import { Input } from "antd";
import styled from "styled-components";
import { theme } from "theme";

export const StyledInput = styled(Input)<{ $isError?: boolean }>`
  height: 48px;

  border-color: ${({ $isError }) =>
    $isError ? theme.color.colors.red : "rgba(255, 255, 255, 0.2)"};

  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;

  .ant-input-prefix {
    margin-inline-end: 16px;
  }

  &.ant-input-outlined.ant-input-disabled {
    color: #fff;
  }

  &.ant-input-affix-wrapper:not(.ant-input-disabled):hover {
    border-color: ${({ $isError }) =>
      $isError ? theme.color.colors.red : "#fff"};
    background: rgba(255, 255, 255, 0.1);
  }
  &.ant-input-affix-wrapper:focus {
    border-color: ${({ $isError }) =>
      $isError ? theme.color.colors.red : "#fff"};
    background: rgba(255, 255, 255, 0.1);
  }
  &.ant-input-outlined:focus-within {
    border-color: ${({ $isError }) =>
      $isError ? theme.color.colors.red : "#fff"};
    background: rgba(255, 255, 255, 0.1);
  }

  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #fff;

  input::placeholder {
    color: rgba(235, 235, 245, 0.6);
  }
  caret-color: #41c4de;
`;
