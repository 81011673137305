import styled from "styled-components";
import { Typography } from "styled/Typography";

export const Title = styled(Typography.H3)`
  text-align: center;
  padding: 0 0 20px;
`;

export const ExpiredTitle = styled(Typography.H4)`
  color: #fff;

  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

export const Description = styled(Typography.Body1Regular)`
  color: rgba(235, 235, 245, 0.8);
  text-align: center;
`;
