import {
  Congrats,
  DeviceAccess,
  Disconnected,
  ExpiredLink,
  Home,
  Join,
  Meeting,
  Session,
} from "pages";
import { FC } from "react";

export interface Route {
  path: string;
  Component: FC;
}

export const LOGIN = "/login";
export const DELETE_ACCOUNT = "/delete-account";

export const ROUTES: Record<string, Route> = {
  HOME: {
    path: "/home",
    Component: Home,
  },
  MEETING_ID: {
    path: "/consultation/:id",
    Component: Meeting,
  },
  CONGRATS: {
    path: "/congrats/:id",
    Component: Congrats,
  },
  SESSION: {
    path: "/session/:id/:name/:token",
    Component: Session,
  },
  DISCONNECTED: {
    path: "/disconnected/:id/:name/:token",
    Component: Disconnected,
  },
  JOIN: {
    path: "/join/:id/:name/:token",
    Component: Join,
  },
  EXPIRED_LINK: {
    path: "/expired",
    Component: ExpiredLink,
  },
  PERMISSIONS: {
    path: "/permissions_denied/:id/:name/:token",
    Component: DeviceAccess,
  },
};
