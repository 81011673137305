import styled from "styled-components";

import { theme } from "theme";

type TypographyProps = {
  $padding?: string;
};

const H1 = styled.h1<TypographyProps>`
  font-family: "Poppins";
  font-style: normal;
  font-size: ${theme.fontSize.h1};
  line-height: ${theme.lineHeight.h1};
  font-weight: ${theme.fontWeight.semiBold};

  ${({ $padding }) => $padding && `padding: ${$padding}`}
`;
const H2 = styled.h2<TypographyProps>`
  font-family: "Poppins";
  font-style: normal;
  font-size: ${theme.fontSize.h2};
  line-height: ${theme.lineHeight.h2};
  font-weight: ${theme.fontWeight.semiBold};

  ${({ $padding }) => $padding && `padding: ${$padding}`}
`;

const H3 = styled.h3<TypographyProps>`
  font-family: "Poppins";
  font-style: normal;
  font-size: ${theme.fontSize.h3};
  line-height: ${theme.lineHeight.h3};
  font-weight: ${theme.fontWeight.medium};

  ${({ $padding }) => $padding && `padding: ${$padding}`}
`;

const H4 = styled.h4<TypographyProps>`
  font-family: "Poppins";
  font-style: normal;
  font-size: ${theme.fontSize.h5};
  line-height: ${theme.lineHeight.h5};
  font-weight: ${theme.fontWeight.medium};

  ${({ $padding }) => $padding && `padding: ${$padding}`}
`;

const H5 = styled.h5<TypographyProps>`
  font-family: "Poppins";
  font-style: normal;
  font-size: ${theme.fontSize.h4};
  line-height: ${theme.lineHeight.h4};
  font-weight: ${theme.fontWeight.medium};

  ${({ $padding }) => $padding && `padding: ${$padding}`}
`;

const Body1Semibold = styled.p<TypographyProps>`
  font-family: "Poppins";
  font-style: normal;
  font-size: ${theme.fontSize.b1};
  line-height: ${theme.lineHeight.b1};
  font-weight: ${theme.fontWeight.semiBold};
  letter-spacing: 0.16px;

  ${({ $padding }) => $padding && `padding: ${$padding}`}
`;

const Body1Regular = styled(Body1Semibold)<TypographyProps>`
  font-weight: ${theme.fontWeight.regular};

  ${({ $padding }) => $padding && `padding: ${$padding}`}
`;

const Body2Semibold = styled.p<TypographyProps>`
  font-style: normal;
  font-size: ${theme.fontSize.b2};
  line-height: ${theme.lineHeight.b2};
  font-weight: ${theme.fontWeight.semiBold};

  ${({ $padding }) => $padding && `padding: ${$padding}`}
`;

const Body2Regular = styled(Body2Semibold)<TypographyProps>`
  font-weight: ${theme.fontWeight.regular};

  ${({ $padding }) => $padding && `padding: ${$padding}`}
`;

export const Typography = {
  H1,
  H2,
  H3,
  H4,
  H5,
  Body1Semibold,
  Body1Regular,
  Body2Semibold,
  Body2Regular,
};
