import { styled } from "styled-components";
import { Typography } from "styled/Typography";

export const Title = styled(Typography.H3)`
  text-align: center;
`;

export const Description = styled(Typography.Body1Regular)`
  text-align: center;
  color: rgba(235, 235, 245, 0.8);
`;
