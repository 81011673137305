import { styled } from "styled-components";
import { Typography } from "styled/Typography";
import { theme } from "theme";

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 393px;
  margin: 0 auto;
  padding: 80px 24px 24px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  align-items: center;

  border-radius: 16px;
  border: 1px solid ${theme.color.colors.red};

  background: ${theme.color.tinted.pink};

  color: ${theme.color.colors.pink};
  font-family: "Poppins";
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px; /* 150% */
  margin-top: 40px;

  span {
    margin-left: 12px;
  }
`;

export const Description = styled(Typography.Body1Regular)`
  color: rgba(235, 235, 245, 0.8);
  text-align: center;
`;

export const Link = styled.a`
  color: #41c4de;

  &:hover {
    color: #2e8ea1;
  }
  &:active {
    color: #2e8ea1;
  }
  &:visited {
    color: #41c4de;
  }
`;
