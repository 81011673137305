import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const FemaleIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 37 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.375 18.375C36.375 27.4141 29.5703 34.9297 20.9375 36.1484V40.3125H25.8125C27.1328 40.3125 28.25 41.4297 28.25 42.75C28.25 44.1719 27.1328 45.1875 25.8125 45.1875H20.9375V50.0625C20.9375 51.4844 19.8203 52.5 18.5 52.5C17.0781 52.5 16.0625 51.4844 16.0625 50.0625V45.1875H11.1875C9.76562 45.1875 8.75 44.1719 8.75 42.75C8.75 41.4297 9.76562 40.3125 11.1875 40.3125H16.0625V36.1484C7.32812 34.9297 0.625 27.5156 0.625 18.375C0.625 8.52344 8.54688 0.5 18.5 0.5C28.3516 0.5 36.375 8.52344 36.375 18.375ZM5.5 18.375C5.5 25.5859 11.2891 31.375 18.5 31.375C25.6094 31.375 31.5 25.5859 31.5 18.375C31.5 11.2656 25.6094 5.375 18.5 5.375C11.2891 5.375 5.5 11.2656 5.5 18.375Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
  </svg>
);
