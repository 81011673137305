import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const VideoIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M14.6001 4.11317C14.3267 3.9665 13.7534 3.81317 12.9734 4.35984L11.9934 5.05317C11.9201 2.97984 11.0201 2.1665 8.83341 2.1665H4.83341C2.55341 2.1665 1.66675 3.05317 1.66675 5.33317V10.6665C1.66675 12.1998 2.50008 13.8332 4.83341 13.8332H8.83341C11.0201 13.8332 11.9201 13.0198 11.9934 10.9465L12.9734 11.6398C13.3867 11.9332 13.7467 12.0265 14.0334 12.0265C14.2801 12.0265 14.4734 11.9532 14.6001 11.8865C14.8734 11.7465 15.3334 11.3665 15.3334 10.4132V5.5865C15.3334 4.63317 14.8734 4.25317 14.6001 4.11317ZM7.83341 7.5865C7.14675 7.5865 6.58008 7.0265 6.58008 6.33317C6.58008 5.63984 7.14675 5.07984 7.83341 5.07984C8.52008 5.07984 9.08675 5.63984 9.08675 6.33317C9.08675 7.0265 8.52008 7.5865 7.83341 7.5865Z"
      fill="white"
    />
  </svg>
);
