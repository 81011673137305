import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const TickIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="108"
    height="102"
    viewBox="0 0 108 102"
    fill="none"
    {...props}
  >
    <path
      d="M91.0522 12.1165L62.3217 4.4182C51.9004 1.62582 44.1978 6.10193 41.3962 16.5575L31.9076 51.9693C29.0601 62.5963 33.5295 70.1869 43.9508 72.9793L72.6813 80.6776C83.2706 83.515 90.7651 79.1301 93.6126 68.5031L103.101 33.0913C105.903 22.6357 101.641 14.9539 91.0522 12.1165Z"
      fill="url(#paint0_linear_2855_345)"
    />
    <g opacity="0.5" filter="url(#filter0_f_2855_345)">
      <path
        d="M83.5455 21.8137L63.9711 16.5688C56.871 14.6663 51.6231 17.7159 49.7144 24.8394L43.2497 48.9658C41.3097 56.206 44.3548 61.3776 51.4549 63.2801L71.0293 68.525C78.2438 70.4581 83.3499 67.4707 85.2899 60.2304L91.7546 36.104C93.6633 28.9805 90.7601 23.7468 83.5455 21.8137Z"
        fill="#39B93A"
      />
    </g>
    <g filter="url(#filter1_b_2855_345)">
      <mask
        id="path-3-outside-1_2855_345"
        maskUnits="userSpaceOnUse"
        x="0.461914"
        y="18"
        width="84"
        height="84"
        fill="black"
      >
        <rect fill="white" x="0.461914" y="18" width="84" height="84" />
        <path d="M60.0169 19H24.6144C10.7719 19 1.46191 28.7183 1.46191 43.1733V76.5342C1.46191 90.9483 10.7719 100.667 24.6144 100.667H60.0169C73.8594 100.667 83.1286 90.9483 83.1286 76.5342V43.1733C83.1286 28.7183 73.8594 19 60.0169 19Z" />
      </mask>
      <path
        d="M60.0169 19H24.6144C10.7719 19 1.46191 28.7183 1.46191 43.1733V76.5342C1.46191 90.9483 10.7719 100.667 24.6144 100.667H60.0169C73.8594 100.667 83.1286 90.9483 83.1286 76.5342V43.1733C83.1286 28.7183 73.8594 19 60.0169 19Z"
        fill="#72DC60"
        fillOpacity="0.35"
      />
      <path
        d="M60.0169 20C60.5692 20 61.0169 19.5523 61.0169 19C61.0169 18.4477 60.5692 18 60.0169 18V20ZM60.0169 18C59.4646 18 59.0169 18.4477 59.0169 19C59.0169 19.5523 59.4646 20 60.0169 20V18ZM60.0169 18H24.6144V20H60.0169V18ZM24.6144 18C17.4504 18 11.3912 20.5203 7.12506 24.9743C2.86247 29.4246 0.461914 35.7291 0.461914 43.1733H2.46191C2.46191 36.1626 4.71635 30.3804 8.5694 26.3578C12.4189 22.3388 17.9359 20 24.6144 20V18ZM0.461914 43.1733V76.5342H2.46191V43.1733H0.461914ZM0.461914 76.5342C0.461914 83.9587 2.86292 90.253 7.12547 94.6979C11.3915 99.1464 17.4504 101.667 24.6144 101.667V99.6667C17.9359 99.6667 12.4186 97.3278 8.56898 93.3136C4.7159 89.2957 2.46191 83.5238 2.46191 76.5342H0.461914ZM24.6144 101.667H60.0169V99.6667H24.6144V101.667ZM60.0169 101.667C67.1811 101.667 73.2303 99.1462 77.4863 94.697C81.7385 90.2517 84.1286 83.9575 84.1286 76.5342H82.1286C82.1286 83.525 79.884 89.2971 76.0411 93.3145C72.2019 97.328 66.6952 99.6667 60.0169 99.6667V101.667ZM84.1286 76.5342V43.1733H82.1286V76.5342H84.1286ZM84.1286 43.1733C84.1286 35.7303 81.739 29.4259 77.4867 24.9752C73.2306 20.5205 67.1811 18 60.0169 18V20C66.6952 20 72.2016 22.3387 76.0406 26.3568C79.8836 30.3791 82.1286 36.1614 82.1286 43.1733H84.1286Z"
        fill="url(#paint1_linear_2855_345)"
        mask="url(#path-3-outside-1_2855_345)"
      />
    </g>
    <g filter="url(#filter2_bd_2855_345)">
      <mask id="path-5-inside-2_2855_345" fill="white">
        <path d="M37.4483 73.0957C36.5336 73.0957 35.619 72.7487 34.9207 72.0504L25.231 62.3607C23.8345 60.9642 23.8345 58.702 25.231 57.3096C26.6275 55.9131 28.8856 55.909 30.2821 57.3055L37.4483 64.4717L54.3043 47.6157C55.7008 46.2192 57.9589 46.2192 59.3554 47.6157C60.7519 49.0122 60.7519 51.2744 59.3554 52.6709L39.9759 72.0504C39.2776 72.7487 38.363 73.0957 37.4483 73.0957Z" />
      </mask>
      <path
        d="M37.4483 73.0957C36.5336 73.0957 35.619 72.7487 34.9207 72.0504L25.231 62.3607C23.8345 60.9642 23.8345 58.702 25.231 57.3096C26.6275 55.9131 28.8856 55.909 30.2821 57.3055L37.4483 64.4717L54.3043 47.6157C55.7008 46.2192 57.9589 46.2192 59.3554 47.6157C60.7519 49.0122 60.7519 51.2744 59.3554 52.6709L39.9759 72.0504C39.2776 72.7487 38.363 73.0957 37.4483 73.0957Z"
        fill="url(#paint2_linear_2855_345)"
      />
      <path
        d="M25.231 57.3096L25.3722 57.4512L25.3724 57.451L25.231 57.3096ZM37.4483 64.4717L37.3069 64.6132L37.4483 64.7546L37.5897 64.6132L37.4483 64.4717ZM59.3554 52.6709L59.214 52.5295L59.3554 52.6709ZM39.9759 72.0504L40.1173 72.1918L39.9759 72.0504ZM37.4483 72.8957C36.5843 72.8957 35.7212 72.568 35.0621 71.909L34.7793 72.1918C35.5167 72.9293 36.483 73.2957 37.4483 73.2957V72.8957ZM35.0621 71.909L25.3724 62.2192L25.0895 62.5021L34.7793 72.1918L35.0621 71.909ZM25.3724 62.2192C24.0539 60.9007 24.0542 58.7653 25.3722 57.4512L25.0898 57.1679C23.6148 58.6386 23.6151 61.0276 25.0895 62.5021L25.3724 62.2192ZM25.3724 57.451C26.6911 56.1323 28.8225 56.1288 30.1406 57.4469L30.4235 57.1641C28.9486 55.6892 26.5639 55.6938 25.0895 57.1681L25.3724 57.451ZM30.1406 57.4469L37.3069 64.6132L37.5897 64.3303L30.4235 57.1641L30.1406 57.4469ZM37.5897 64.6132L54.4457 47.7572L54.1629 47.4743L37.3069 64.3303L37.5897 64.6132ZM54.4457 47.7572C55.7641 46.4388 57.8956 46.4388 59.214 47.7572L59.4968 47.4743C58.0222 45.9997 55.6375 45.9997 54.1629 47.4743L54.4457 47.7572ZM59.214 47.7572C60.5324 49.0756 60.5324 51.2111 59.214 52.5295L59.4968 52.8123C60.9714 51.3377 60.9714 48.9489 59.4968 47.4743L59.214 47.7572ZM59.214 52.5295L39.8345 71.909L40.1173 72.1918L59.4968 52.8123L59.214 52.5295ZM39.8345 71.909C39.1754 72.568 38.3124 72.8957 37.4483 72.8957V73.2957C38.4136 73.2957 39.3799 72.9293 40.1173 72.1918L39.8345 71.909Z"
        fill="url(#paint3_linear_2855_345)"
        fillOpacity="0.5"
        mask="url(#path-5-inside-2_2855_345)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2855_345"
        x="28.6764"
        y="2.01416"
        width="77.65"
        height="81.0854"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="7"
          result="effect1_foregroundBlur_2855_345"
        />
      </filter>
      <filter
        id="filter1_b_2855_345"
        x="-23.5381"
        y="-6"
        width="131.667"
        height="131.667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2855_345"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2855_345"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_bd_2855_345"
        x="9.18359"
        y="31.5684"
        width="66.2191"
        height="56.5273"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2855_345"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_2855_345"
          result="effect2_dropShadow_2855_345"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2855_345"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2855_345"
        x1="107.538"
        y1="16.5338"
        x2="44.8821"
        y2="54.8808"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9BF763" />
        <stop offset="1" stopColor="#26AB5B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2855_345"
        x1="14.4619"
        y1="28.5143"
        x2="66.5566"
        y2="93.0347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2855_345"
        x1="57.8349"
        y1="51.3587"
        x2="17.5271"
        y2="53.153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2855_345"
        x1="28.0852"
        y1="55.467"
        x2="58.8395"
        y2="55.6988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
