import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const MicOffIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    {...props}
  >
    <path
      d="M19.6566 7.48959V8.84292L11.1633 17.3363C10.0433 16.3213 9.34326 14.8279 9.34326 13.2296V7.48959C9.34326 5.08626 10.9766 3.09126 13.1933 2.51959C13.4149 2.46126 13.6249 2.64792 13.6249 2.86959V4.66626C13.6249 5.14459 14.0216 5.54126 14.4999 5.54126C14.9783 5.54126 15.3749 5.14459 15.3749 4.66626V2.86959C15.3749 2.64792 15.5849 2.46126 15.8066 2.51959C18.0233 3.09126 19.6566 5.08626 19.6566 7.48959Z"
      fill="black"
    />
    <path
      d="M23.6117 11.4446V13.2996C23.6117 18.048 19.9601 21.9563 15.3167 22.3646V24.8496C15.3167 25.3046 14.9551 25.6663 14.5001 25.6663C14.0451 25.6663 13.6834 25.3046 13.6834 24.8496V22.3646C12.4117 22.248 11.2101 21.8746 10.1367 21.2796L11.3384 20.078C12.2951 20.5213 13.3684 20.778 14.5001 20.778C18.6301 20.778 21.9901 17.418 21.9901 13.2996V11.4446C21.9901 11.0013 22.3517 10.6396 22.8067 10.6396C23.2501 10.6396 23.6117 11.0013 23.6117 11.4446Z"
      fill="black"
    />
    <path
      d="M19.6565 11.7598V13.4514C19.6565 16.4614 17.0665 18.8764 13.9865 18.5848C13.6598 18.5498 13.3331 18.4914 13.0298 18.3864L19.6565 11.7598Z"
      fill="black"
    />
    <path
      d="M25.8985 2.60137C25.5485 2.25137 24.9768 2.25137 24.6268 2.60137L8.93514 18.293C7.73347 16.9747 7.01014 15.2247 7.01014 13.2997V11.4447C7.01014 11.0014 6.64847 10.6397 6.19347 10.6397C5.75014 10.6397 5.38847 11.0014 5.38847 11.4447V13.2997C5.38847 15.668 6.29847 17.8264 7.78014 19.448L3.09014 24.138C2.74014 24.488 2.74014 25.0597 3.09014 25.4097C3.2768 25.573 3.49847 25.6664 3.7318 25.6664C3.96514 25.6664 4.1868 25.573 4.3618 25.398L25.8985 3.86137C26.2601 3.51137 26.2601 2.95137 25.8985 2.60137Z"
      fill="black"
    />
  </svg>
);
