// Lib
import { forwardRef } from "react";
import { ControllerFieldState } from "react-hook-form";
import { InputProps as AntInputProps, InputRef } from "antd";
// Styled
import { StyledInput } from "./styled";

interface InputProps extends AntInputProps {
  fieldState?: ControllerFieldState;
  fieldError?: boolean | string;
}

export const Input = forwardRef<InputRef, InputProps>(
  ({ fieldError, fieldState, ...props }, ref) => {
    const error = fieldError || fieldState?.error?.message || false;

    return <StyledInput ref={ref} {...props} $isError={!!error} />;
  },
);

Input.displayName = "Input";
