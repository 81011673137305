import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const EyeInvisibleIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
    {...props}
  >
    <path
      d="M17.725 7.64992C17.4834 7.26659 17.225 6.90825 16.9584 6.57492C16.65 6.18325 16.0667 6.14992 15.7167 6.49992L13.2167 8.99992C13.4 9.54992 13.4334 10.1833 13.2667 10.8416C12.975 12.0166 12.025 12.9666 10.85 13.2583C10.4208 13.3669 10.0022 13.3906 9.61045 13.3431C9.23443 13.2975 8.83949 13.3771 8.57166 13.645C8.14654 14.0701 7.48881 14.7278 6.95837 15.2583C6.54171 15.6749 6.67504 16.4083 7.23337 16.6249C8.12504 16.9666 9.05004 17.1416 10 17.1416C11.4834 17.1416 12.925 16.7083 14.2417 15.8999C15.5834 15.0666 16.7917 13.8416 17.7667 12.2833C18.5584 11.0249 18.5167 8.90825 17.725 7.64992Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
    <path
      d="M11.6834 8.3167L8.3167 11.6834C7.8917 11.25 7.6167 10.65 7.6167 10C7.6167 8.6917 8.68337 7.6167 10 7.6167C10.65 7.6167 11.25 7.8917 11.6834 8.3167Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
    <path
      d="M14.3039 4.1444C14.8069 4.46123 14.8418 5.15831 14.4215 5.57865L13.0953 6.9048C12.7029 7.2972 12.0692 7.27763 11.5783 7.01872C11.1082 6.77075 10.5713 6.6334 10.0001 6.6334C8.13341 6.6334 6.63341 8.14173 6.63341 10.0001C6.63341 10.5734 6.77494 11.1122 7.02514 11.5836C7.28383 12.0709 7.30127 12.6998 6.91173 13.0905L5.01218 14.9957C4.8944 15.1138 4.70373 15.1159 4.58341 15.0003C3.65841 14.2503 3.01675 13.5001 2.29175 12.3667C1.45841 11.0584 1.45841 8.9334 2.29175 7.62506C3.25841 6.1084 4.44175 4.91673 5.75841 4.10007C7.07508 3.30007 8.51675 2.8584 10.0001 2.8584C11.5071 2.8584 12.9758 3.30781 14.3039 4.1444Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
    <path
      d="M12.3834 10.0001C12.3834 11.3084 11.3167 12.3834 10.0001 12.3834C9.95007 12.3834 9.9084 12.3834 9.8584 12.3667L12.3667 9.8584C12.3834 9.9084 12.3834 9.95007 12.3834 10.0001Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
    <path
      d="M18.4291 2.6875C18.1791 2.4375 17.7708 2.4375 17.5208 2.6875L2.14575 18.0708C1.89575 18.3208 1.89575 18.7292 2.14575 18.9792C2.27075 19.0958 2.42909 19.1625 2.59575 19.1625C2.76242 19.1625 2.92075 19.0958 3.04575 18.9708L18.4291 3.5875C18.6874 3.3375 18.6874 2.9375 18.4291 2.6875Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
  </svg>
);
