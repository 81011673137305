import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const CloseIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.227806 0.227806C0.531547 -0.0759353 1.02401 -0.0759353 1.32775 0.227806L13.7722 12.6723C14.0759 12.976 14.0759 13.4685 13.7722 13.7722C13.4685 14.0759 12.976 14.0759 12.6723 13.7722L0.227806 1.32775C-0.0759353 1.02401 -0.0759353 0.531547 0.227806 0.227806Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7722 0.227806C14.0759 0.531547 14.0759 1.02401 13.7722 1.32775L1.32775 13.7722C1.02401 14.0759 0.531547 14.0759 0.227806 13.7722C-0.0759353 13.4685 -0.0759353 12.976 0.227806 12.6723L12.6723 0.227806C12.976 -0.0759353 13.4685 -0.0759353 13.7722 0.227806Z"
      fill="black"
    />
  </svg>
);
