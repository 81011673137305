import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const ClockIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.49992 1.3335C4.82659 1.3335 1.83325 4.32683 1.83325 8.00016C1.83325 11.6735 4.82659 14.6668 8.49992 14.6668C12.1733 14.6668 15.1666 11.6735 15.1666 8.00016C15.1666 4.32683 12.1733 1.3335 8.49992 1.3335ZM11.3999 10.3802C11.3066 10.5402 11.1399 10.6268 10.9666 10.6268C10.8799 10.6268 10.7933 10.6068 10.7133 10.5535L8.64659 9.32016C8.13325 9.0135 7.75325 8.34016 7.75325 7.74683V5.0135C7.75325 4.74016 7.97992 4.5135 8.25325 4.5135C8.52659 4.5135 8.75325 4.74016 8.75325 5.0135V7.74683C8.75325 7.98683 8.95325 8.34016 9.15992 8.46016L11.2266 9.6935C11.4666 9.8335 11.5466 10.1402 11.3999 10.3802Z"
      fill="white"
    />
  </svg>
);
