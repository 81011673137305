// Lib
import { PropsWithChildren, createContext } from "react";
import { notification } from "antd";
// Icons
// Components

interface NotificationButton {
  label: string;
  onClick: () => void;
}

export interface OpenNotificationType {
  duration?: number;
  message: string;
  description?: string;
  updateKey?: string;
  type?: "success" | "info" | "warning" | "error";
  notificationButton?: NotificationButton;
  onClose?: () => void;
}

export interface NotificationsContextType {
  openNotification: (args: OpenNotificationType) => void;
}

export const NotificationsContext = createContext(null);

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = ({
    duration = 5,
    message,
    description,
    type = "success",
    notificationButton,
    updateKey,
    onClose,
  }: OpenNotificationType) => {
    const key = updateKey
      ? updateKey
      : type === "success"
      ? `open${Date.now()}`
      : "updatable";

    api[type]({
      className: `notification-${type} ${
        !!notificationButton && "notification-button"
      }`,
      placement: "bottomLeft",
      message,
      description,
      key,
      duration,
      onClose,
    });
  };

  return (
    <NotificationsContext.Provider value={{ openNotification }}>
      {children}
      {contextHolder}
    </NotificationsContext.Provider>
  );
};
