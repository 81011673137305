// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import { AuthUserRequest, AuthUserResponse } from "types/auth";
// Utils
import { baseQuery } from "utils/baseQuery";

import { endpoints } from "consts";

export const loginAPI = createApi({
  reducerPath: "loginAPI",
  baseQuery: baseQuery(),
  tagTypes: ["loginAPI"],

  endpoints: build => ({
    login: build.query<AuthUserResponse, AuthUserRequest>({
      query: data => ({
        url: endpoints.signIn,
        method: "POST",
        data,
      }),
      providesTags: ["loginAPI"],
    }),
    deleteAccount: build.mutation<void, void>({
      query: () => ({
        url: endpoints.deleteAccount,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useLazyLoginQuery, useDeleteAccountMutation } = loginAPI;
