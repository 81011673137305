// Lib
import { FC } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
import { Badge } from "components";
// Styled
import { Container, QuestionTitle } from "./styled";
import { FlexContainer } from "styled/Box";

interface AdditionalInformationProps {
  title: string;
  data: string[];
}

export const AdditionalInformation: FC<AdditionalInformationProps> = ({
  title,
  data,
}) => {
  return (
    <Container>
      <QuestionTitle $padding="0 0 16px 0">{title}</QuestionTitle>

      <FlexContainer $fullwidth $wrap>
        {data.map(i => (
          <Badge key={i} title={i} />
        ))}
      </FlexContainer>
    </Container>
  );
};
