// Lib
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled

import { useNotification } from "hooks/useNotification";
import { FC, useEffect, useRef } from "react";
import useIsRecording from "twillio/hooks/useIsRecording/useIsRecording";

export const RecordingNotifications: FC = () => {
  const prevIsRecording = useRef<boolean | null>(null);
  const isRecording = useIsRecording();
  const { openNotification } = useNotification();

  useEffect(() => {
    // Show "Recording in progress" snackbar when a user joins a room that is recording
    if (isRecording && prevIsRecording.current === null) {
      openNotification({ message: "Recording in progress", type: "success" });
    }
  }, [isRecording]);

  useEffect(() => {
    // Show "Recording started" snackbar when recording has started.
    if (isRecording && prevIsRecording.current === false) {
      openNotification({ message: "Recording started", type: "success" });
    }
  }, [isRecording]);

  useEffect(() => {
    // Show "Recording finished" snackbar when recording has stopped.
    if (!isRecording && prevIsRecording.current === true) {
      openNotification({ message: "Recording finished", type: "success" });
    }
  }, [isRecording]);

  useEffect(() => {
    prevIsRecording.current = isRecording;
  }, [isRecording]);

  return null;
};
