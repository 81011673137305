import axios, { AxiosInstance } from "axios";

import { store } from "store";
import { userLogout } from "rtk/slices";

import { token } from "./handleToken";
import { API_BASE_URL } from "./config";

export const CUSTOM_JSON_HEADERS = {
  "Content-Type": "application/json",
  accept: "application/json",
};

const UNAUTHORIZED = 401;

export const axiosInstanceRaw: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: CUSTOM_JSON_HEADERS,
});

const axiosInstanceGenerator = (baseUrl?: string) => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: baseUrl,
    headers: CUSTOM_JSON_HEADERS,
  });

  axiosInstance.interceptors.request.use(
    config => {
      const access = token.access.get();

      if (access?.token) {
        config.headers["Authorization"] = `Token ${access.token}`;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (error.response?.status === UNAUTHORIZED) {
        store.dispatch(userLogout());
        return Promise.reject(error);
      }

      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

export const axiosBaseInstance = axiosInstanceGenerator(API_BASE_URL);
