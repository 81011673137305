// Lib
import { FC, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
import { useLazySendTakeAwaysQuery } from "rtk/query/consultationAPI";
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
import { GlassInfoIcon, TickIcon } from "icons";
// Layouts
// Components
import { Modal, TextEditor } from "components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import {
  AccentText,
  Bordered,
  ButtonTransparent,
  Description,
  ErrorMessage,
  InfoDescription,
  InfoNotice,
  InfoTitle,
  Notice,
  NoticeSmall,
  Wrapper,
} from "./styled";

export const Congrats: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [send, { isLoading }] = useLazySendTakeAwaysQuery();

  const [feedBackModal, setFeedBackModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const feedBackRef = useRef<null | string>(null);

  const handleRedirect = () => {
    navigate("/home");
  };

  const handleCheckValidation = (value: string) => {
    const stringLenght = value.replace(/<[^>]+>/g, "").trim()?.length;

    if (stringLenght > 0 && stringLenght < 1001) {
      setIsValid(true);
      setIsErrorMessage(false);
    }

    if (stringLenght > 1000) {
      setIsErrorMessage(true);
      setIsValid(false);
    }

    if (stringLenght === 0) {
      setIsValid(false);
      setIsErrorMessage(false);
    }
  };

  const onChange = (value: string) => {
    const cleanedString = value
      .replace(/&nbsp;/g, " ")
      .replace(/(<[^>]+) style=".*?"/g, "$1");

    handleCheckValidation(cleanedString);
    feedBackRef.current = cleanedString;
  };

  const handleSend = async () => {
    try {
      if (feedBackRef?.current && feedBackRef?.current?.length) {
        await send({ id, key_takeaway: feedBackRef.current }).unwrap();
      }
    } catch (error) {
      console.log(error);
    } finally {
      navigate("/home");
    }
  };

  const handleClose = () => {
    setConfirmModal(true);
  };

  return (
    <>
      <Wrapper>
        <FlexContainer
          $fullwidth
          $column
          $align="center"
          $justify="center"
          $padding="20px 0"
        >
          <TickIcon />

          <Typography.H4 $padding="24px 0">Congratulations!</Typography.H4>

          <Description>
            Thank you for your hard work! We are grateful for your service.
          </Description>

          <FlexContainer $fullwidth $column $padding="24px 0">
            <ButtonTransparent onClick={handleRedirect}>
              Create later
            </ButtonTransparent>

            <Button.Base onClick={() => setFeedBackModal(true)}>
              Create key takeaways
            </Button.Base>
          </FlexContainer>

          <Notice>
            Kindly provide feedback promptly, within 24 hours after the
            counseling session ends.
          </Notice>
        </FlexContainer>
      </Wrapper>

      <Modal
        open={feedBackModal}
        title="Create key takeaways"
        onInfo={() => setInfoModal(true)}
        onClose={handleClose}
      >
        <FlexContainer $fullwidth $column>
          <FlexContainer $fullwidth $column $padding="48px 0 24px">
            <TextEditor
              placeholder={"Type your takeaways here"}
              onChange={onChange}
            />

            <ErrorMessage>
              {isErrorMessage && "Takeaways must be less than 1000 characters"}
            </ErrorMessage>

            <Button.Base
              loading={isLoading}
              disabled={!isValid}
              onClick={handleSend}
            >
              Send Key Takeaways
            </Button.Base>
          </FlexContainer>

          <NoticeSmall>
            Kindly provide feedback promptly, within 24 hours after the
            counseling session ends.
          </NoticeSmall>
        </FlexContainer>
      </Modal>

      <Modal open={infoModal} onClose={() => setInfoModal(false)}>
        <FlexContainer $fullwidth $column $align="center" $justify="center">
          <GlassInfoIcon />

          <FlexContainer $column $padding="48px 0 0">
            <InfoTitle $padding="0 0 8px">
              How to give quality takeaways?
            </InfoTitle>

            <InfoDescription>
              Please share some concise takeaways for the user, including a
              <AccentText> brief summary</AccentText> of the problems discussed,
              <AccentText> coping strategies</AccentText> for the present or
              future, any <AccentText> observed</AccentText> improvements during
              the session, and <AccentText> acknowledgment</AccentText> of the
              user&apos;s <AccentText> awareness</AccentText> about mental
              well-being.
            </InfoDescription>
          </FlexContainer>

          <InfoNotice>
            Kindly provide feedback promptly, within 24 hours after the
            counseling session ends.
          </InfoNotice>

          <Button.Base onClick={() => setInfoModal(false)}>Close</Button.Base>
        </FlexContainer>
      </Modal>

      <Modal open={confirmModal} onClose={() => setConfirmModal(false)}>
        <FlexContainer $fullwidth $column $align="center" $justify="center">
          <GlassInfoIcon />

          <div />

          <InfoTitle>Are you sure to want to leave without sending?</InfoTitle>

          <FlexContainer $fullwidth $column>
            <Bordered onClick={() => setConfirmModal(false)}>No</Bordered>

            <Button.Base onClick={handleRedirect}>Yes</Button.Base>
          </FlexContainer>
        </FlexContainer>
      </Modal>
    </>
  );
};
