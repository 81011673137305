// Lib
import { FC } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
import { ConsultationType, ProfileQuestioneer } from "types/consultation";
// Theme
// Constants
// Helpers
// Utils
// Icons
import { ArrowLeftIcon, AudioIcon, ClockIcon, VideoIcon } from "icons";
// Layouts
// Components
import { Timer } from "components";
import { ProfileData } from "../ProfileData";
import { QuestionGroup } from "../QuestionGroup";
import { DoctorInformation } from "../DoctorInformation";
import { AdditionalInformation } from "../AdditionalInformation";
import { EmergencyContact } from "../EmergencyContact";
import { ScreeningQuestions } from "../ScreeningQuestions";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { Info, Space, Title, Wrapper } from "./styled";

interface UserProfileProps {
  data: ProfileQuestioneer;
  created_at: string;
  handleAprove: () => void;
  handlePrev: () => void;
  onTimeIsOut: () => void;
}

export const UserProfile: FC<UserProfileProps> = ({
  data,
  created_at,
  handlePrev,
  handleAprove,
  onTimeIsOut,
}) => {
  return (
    <>
      <FlexContainer
        $fullwidth
        $align="center"
        $justify="space-between"
        $padding="16px"
      >
        <Button.IconRounded onClick={handlePrev}>
          <ArrowLeftIcon />
        </Button.IconRounded>

        <Typography.H4>User Profile</Typography.H4>

        <Timer type="badge" startDate={created_at} onTimeIsOut={onTimeIsOut} />
      </FlexContainer>

      <Wrapper>
        <Title>Requested Counseling Method</Title>

        <Space />

        <FlexContainer $fullwidth $padding="0 0 8px">
          {!!data?.type && (
            <Info $margin="0 8px 0 0">
              {data.type === ConsultationType.Video && (
                <>
                  <VideoIcon /> Videocall
                </>
              )}
              {data.type === ConsultationType.Audio && (
                <>
                  <AudioIcon /> Audiocall
                </>
              )}
            </Info>
          )}

          {!!data?.time && (
            <Info>
              <ClockIcon />
              {data?.time} Min
            </Info>
          )}
        </FlexContainer>

        <ProfileData data={data?.profile} />

        {data?.emergencyContact && (
          <EmergencyContact data={data?.emergencyContact} />
        )}

        <QuestionGroup data={data?.mentalHealthBackground} />

        {data?.doctorInformation && (
          <DoctorInformation data={data?.doctorInformation} />
        )}

        {!!data?.additionalInformation?.length && (
          <AdditionalInformation
            title={"Additional Information"}
            data={data?.additionalInformation}
          />
        )}

        {!!data?.screeningQuestions && (
          <ScreeningQuestions data={data?.screeningQuestions} />
        )}

        <FlexContainer $fullwidth $padding="16px 0 35px">
          <Button.Base onClick={handleAprove}>Start</Button.Base>
        </FlexContainer>
      </Wrapper>
    </>
  );
};
