// Lib
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
import { GlassDangerIcon } from "icons";
// Layouts
// Components
// Styled
import { Description, ExpiredTitle } from "./styled";
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

export const DeviceAccess: FC = () => {
  const { id, name, token } = useParams();

  const navigate = useNavigate();

  return (
    <FlexContainer $fullwidth $justify="center">
      <FlexContainer
        $fullwidth
        $column
        $width="361px"
        $align="center"
        $justify="center"
        $padding="80px 20px"
      >
        <GlassDangerIcon />

        <ExpiredTitle>
          Permission Request for Video/Audio Call Features
        </ExpiredTitle>

        <Description>
          To provide you with the best video calling experience, our application
          requires access to microphone, and camera. These permissions are
          essential for ensuring optimal audio quality, clear two-way
          communication, and seamless video transmission during calls. Your
          consent to these permissions will enable us to deliver a more
          immersive and engaging video calling experience. Thank you for your
          understanding.
        </Description>

        <Button.Base
          onClick={() => navigate(`/session/${id}/${name}/${token}`)}
        >
          Try again
        </Button.Base>
      </FlexContainer>
    </FlexContainer>
  );
};
