import Participant from "../Participant/Participant";

import { usePagination } from "./usePagination/usePagination";

import useDominantSpeaker from "twillio/hooks/useDominantSpeaker/useDominantSpeaker";
import useParticipantsContext from "twillio/hooks/useParticipantsContext/useParticipantsContext";
import useVideoContext from "twillio/hooks/useVideoContext/useVideoContext";
import useGalleryViewLayout from "twillio/hooks/useGalleryViewLayout/useGalleryViewLayout";
import {
  Container,
  NotificationContainer,
  ParticipantContainer,
} from "./styled";

import { MeetingTimer } from "../MeetingTimer/MeetingTimer";
import { Typography } from "styled/Typography";

export function GalleryView() {
  const { room } = useVideoContext();
  const { galleryViewParticipants } = useParticipantsContext();
  const dominantSpeaker = useDominantSpeaker(true);

  const { paginatedParticipants, setCurrentPage, currentPage } = usePagination([
    room!.localParticipant,
    ...galleryViewParticipants,
  ]);

  const { containerRef } = useGalleryViewLayout(2);

  const participants = paginatedParticipants;

  const isVideo = window.sessionStorage.getItem("type") === "video";

  return (
    <div>
      <div>
        {!(currentPage === 1) && (
          <div onClick={() => setCurrentPage(page => page - 1)}>arrow back</div>
        )}
      </div>

      <MeetingTimer />

      {participants?.length === 1 ? (
        <NotificationContainer>
          <Typography.Body1Regular>
            Please wait a moment until another participant joins the meeting
          </Typography.Body1Regular>
        </NotificationContainer>
      ) : null}

      <Container
        ref={containerRef}
        $paginatedParticipantsCount={participants?.length}
        $isVideo={isVideo}
      >
        {participants.map((participant, i) => (
          <ParticipantContainer
            key={participant.sid}
            $index={i}
            $hide={!isVideo}
          >
            <Participant
              participant={participant}
              isLocalParticipant={participant === room!.localParticipant}
              isDominantSpeaker={participant === dominantSpeaker}
            />
          </ParticipantContainer>
        ))}
      </Container>
    </div>
  );
}
