// Lib
import { FC, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// Api
import {
  useGetCurrentConsultationQuery,
  useLazyGetCurrentConsultationCheckQuery,
} from "rtk/query/consultationAPI";
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
import { calculateTime } from "utils/calculateTime";
import { createProfileQuestions } from "utils/createProfileQuestions";
// Icons
// Layouts
// Components
import { IncomingRequest, UserProfile } from "./components";

// Styled

export const Meeting: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [check] = useLazyGetCurrentConsultationCheckQuery();

  const { data, error } = useGetCurrentConsultationQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if ((error as { status: number })?.status === 404) {
      navigate("/home");
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      window.sessionStorage.setItem("type", data?.consultation_request?.type);
    }
  }, [data]);

  const [searchParams, setSearchParams] = useSearchParams();

  const step = searchParams.get("step");

  const handlePrev = () => {
    setSearchParams({});
  };

  const handlePreview = () => {
    setSearchParams({ step: "profile" });
  };

  const onTimeIsOut = async () => {
    try {
      const { status } = await check().unwrap();

      if (status === "accepted") {
        navigate(`/home/`);
        return;
      }

      if (status === "started") {
        return;
      }

      navigate(`/home/`);
    } catch (error) {
      console.log(error);
      navigate(`/home`);
      return;
    }
  };

  const handleAprove = async () => {
    try {
      const { status } = await check().unwrap();

      if (status === "expired") {
        navigate(`/expired/`);
        return;
      }
    } catch (error) {
      console.log(error);
      navigate(`/home`);
      return;
    }

    const isHaveAllVariables =
      data?.counselor_access_token &&
      data?.room_sid &&
      data?.consultation_request?.id;

    if (isHaveAllVariables) {
      navigate(
        `/session/${data?.consultation_request?.id}/${data?.room_sid}/${data?.counselor_access_token}`,
      );
    }
  };

  if (step === "profile") {
    return (
      <UserProfile
        data={createProfileQuestions(data?.consultation_request)}
        created_at={calculateTime(data?.created_at)}
        handlePrev={handlePrev}
        handleAprove={handleAprove}
        onTimeIsOut={onTimeIsOut}
      />
    );
  }

  return (
    <IncomingRequest
      created_at={calculateTime(data?.created_at)}
      handlePreview={handlePreview}
      onTimeIsOut={onTimeIsOut}
    />
  );
};
