import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const LockIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M9.99994 14.4584C10.7501 14.4584 11.3583 13.8502 11.3583 13.1C11.3583 12.3498 10.7501 11.7417 9.99994 11.7417C9.24975 11.7417 8.6416 12.3498 8.6416 13.1C8.6416 13.8502 9.24975 14.4584 9.99994 14.4584Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
    <path
      d="M15.2332 7.9415V6.89984C15.2332 4.64984 14.6915 1.6665 9.99984 1.6665C5.30817 1.6665 4.7665 4.64984 4.7665 6.89984V7.9415C2.43317 8.23317 1.6665 9.4165 1.6665 12.3248V13.8748C1.6665 17.2915 2.70817 18.3332 6.12484 18.3332H13.8748C17.2915 18.3332 18.3332 17.2915 18.3332 13.8748V12.3248C18.3332 9.4165 17.5665 8.23317 15.2332 7.9415ZM9.99984 15.6165C8.60817 15.6165 7.48317 14.4832 7.48317 13.0998C7.48317 11.7082 8.6165 10.5832 9.99984 10.5832C11.3832 10.5832 12.5165 11.7165 12.5165 13.0998C12.5165 14.4915 11.3915 15.6165 9.99984 15.6165ZM6.12484 7.8665C6.05817 7.8665 5.99984 7.8665 5.93317 7.8665V6.89984C5.93317 4.45817 6.62484 2.83317 9.99984 2.83317C13.3748 2.83317 14.0665 4.45817 14.0665 6.89984V7.87484C13.9998 7.87484 13.9415 7.87484 13.8748 7.87484H6.12484V7.8665Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
  </svg>
);
