import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const GlassClockIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="110"
    height="108"
    viewBox="0 0 110 108"
    fill="none"
    {...props}
  >
    <path
      d="M56.5098 15.7666L28.6922 23.0682C17.8154 25.9231 12.5461 35.3236 15.5895 46.4499L22.6134 72.1283C25.6481 83.2232 35.0096 88.7834 45.8864 85.9285L73.7041 78.6269C84.5809 75.7719 89.818 66.3798 86.7832 55.285L79.7594 29.6065C76.716 18.4803 67.3866 12.9116 56.5098 15.7666Z"
      fill="url(#paint0_linear_2842_843)"
    />
    <g opacity="0.5" filter="url(#filter0_f_2842_843)">
      <rect
        x="32.5156"
        y="75.9272"
        width="43.6506"
        height="45.4523"
        rx="13"
        transform="rotate(-105 32.5156 75.9272)"
        fill="url(#paint1_linear_2842_843)"
      />
    </g>
    <g filter="url(#filter1_b_2842_843)">
      <path
        d="M87.0762 32.8418H54.2588C41.4271 32.8418 32.7969 41.6666 32.7969 54.7927V85.0864C32.7969 98.1753 41.4271 107 54.2588 107H87.0762C99.9079 107 108.5 98.1753 108.5 85.0864V54.7927C108.5 41.6666 99.9079 32.8418 87.0762 32.8418Z"
        fill="#41C4DE"
        fillOpacity="0.25"
      />
      <path
        d="M87.0762 32.3418H54.2588C47.7248 32.3418 42.2249 34.5911 38.3584 38.5454C34.4929 42.4987 32.2969 48.1183 32.2969 54.7927V85.0864C32.2969 91.7426 34.4931 97.3529 38.3586 101.301C42.225 105.251 47.7248 107.5 54.2588 107.5H87.0762C93.6102 107.5 99.1008 105.251 102.958 101.301C106.814 97.3522 109 91.742 109 85.0864V54.7927C109 48.1189 106.814 42.4994 102.958 38.5459C99.1009 34.5912 93.6103 32.3418 87.0762 32.3418Z"
        stroke="url(#paint2_linear_2842_843)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#filter2_bd_2842_843)">
      <mask id="path-5-inside-1_2842_843" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.7197 83.6758C83.1777 83.9391 83.6773 84.0652 84.1732 84.0652C85.1384 84.0652 86.0809 83.5794 86.6146 82.7081C86.8811 82.2698 87.0102 81.789 87.0147 81.3133C87.0149 81.2932 87.0148 81.273 87.0146 81.2529C87.003 80.3167 86.5095 79.4081 85.6305 78.8914L72.1553 71.0195L72.1553 53.8864C72.1553 52.3513 70.8835 51.1055 69.3164 51.1055C67.7494 51.1055 66.4775 52.3513 66.4775 53.8864V72.5991V72.6003C66.4775 73.5792 67.0037 74.4876 67.8591 74.9919L82.7197 83.6758Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.7197 83.6758C83.1777 83.9391 83.6773 84.0652 84.1732 84.0652C85.1384 84.0652 86.0809 83.5794 86.6146 82.7081C86.8811 82.2698 87.0102 81.789 87.0147 81.3133C87.0149 81.2932 87.0148 81.273 87.0146 81.2529C87.003 80.3167 86.5095 79.4081 85.6305 78.8914L72.1553 71.0195L72.1553 53.8864C72.1553 52.3513 70.8835 51.1055 69.3164 51.1055C67.7494 51.1055 66.4775 52.3513 66.4775 53.8864V72.5991V72.6003C66.4775 73.5792 67.0037 74.4876 67.8591 74.9919L82.7197 83.6758Z"
        fill="url(#paint3_linear_2842_843)"
      />
      <path
        d="M82.7197 83.6758L82.5179 84.0212L82.5204 84.0226L82.7197 83.6758ZM86.6146 82.7081L86.9557 82.917L86.9564 82.9158L86.6146 82.7081ZM87.0147 81.3133L87.4147 81.3171V81.317L87.0147 81.3133ZM87.0146 81.2529L86.6146 81.2578L86.6146 81.258L87.0146 81.2529ZM85.6305 78.8914L85.8332 78.5466L85.8322 78.5461L85.6305 78.8914ZM72.1553 71.0195H71.7553V71.2491L71.9535 71.3649L72.1553 71.0195ZM72.1553 53.8864H72.5553H72.1553ZM67.8591 74.9919L67.656 75.3365L67.6573 75.3372L67.8591 74.9919ZM84.1732 83.6652C83.7457 83.6652 83.315 83.5567 82.919 83.329L82.5204 84.0226C83.0404 84.3215 83.609 84.4652 84.1732 84.4652V83.6652ZM86.2735 82.4991C85.8163 83.2456 85.0066 83.6652 84.1732 83.6652V84.4652C85.2702 84.4652 86.3455 83.9132 86.9557 82.917L86.2735 82.4991ZM86.6147 81.3095C86.6109 81.7148 86.5011 82.1248 86.2728 82.5003L86.9564 82.9158C87.2611 82.4147 87.4095 81.8632 87.4147 81.3171L86.6147 81.3095ZM86.6146 81.258C86.6148 81.2752 86.6149 81.2923 86.6147 81.3095L87.4147 81.317C87.4149 81.294 87.4148 81.2709 87.4146 81.2478L86.6146 81.258ZM87.4146 81.248C87.4013 80.1746 86.8354 79.1357 85.8332 78.5466L85.4278 79.2363C86.1837 79.6806 86.6048 80.4588 86.6146 81.2578L87.4146 81.248ZM85.8322 78.5461L72.3571 70.6741L71.9535 71.3649L85.4287 79.2368L85.8322 78.5461ZM71.7553 53.8864L71.7553 71.0195H72.5553L72.5553 53.8864H71.7553ZM69.3164 51.5055C70.6704 51.5055 71.7553 52.58 71.7553 53.8864H72.5553C72.5553 52.1227 71.0966 50.7055 69.3164 50.7055V51.5055ZM66.8775 53.8864C66.8775 52.58 67.9625 51.5055 69.3164 51.5055V50.7055C67.5363 50.7055 66.0775 52.1227 66.0775 53.8864H66.8775ZM66.8775 72.5991V53.8864H66.0775V72.5991H66.8775ZM66.8775 72.6003V72.5991H66.0775V72.6003H66.8775ZM68.0623 74.6473C67.3261 74.2133 66.8775 73.4344 66.8775 72.6003H66.0775C66.0775 73.7239 66.6813 74.7619 67.656 75.3365L68.0623 74.6473ZM82.9215 83.3305L68.0609 74.6465L67.6573 75.3372L82.5179 84.0212L82.9215 83.3305Z"
        fill="url(#paint4_linear_2842_843)"
        mask="url(#path-5-inside-1_2842_843)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2842_843"
        x="0.136719"
        y="0.918457"
        width="97.3638"
        height="96.0903"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="12"
          result="effect1_foregroundBlur_2842_843"
        />
      </filter>
      <filter
        id="filter1_b_2842_843"
        x="7.79688"
        y="7.8418"
        width="125.703"
        height="124.158"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2842_843"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2842_843"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_bd_2842_843"
        x="51.4775"
        y="36.1055"
        width="50.5371"
        height="62.9595"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2842_843"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.577356 0 0 0 0 0.359375 0 0 0 0 0.9375 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_2842_843"
          result="effect2_dropShadow_2842_843"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2842_843"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2842_843"
        x1="92"
        y1="78"
        x2="14.9957"
        y2="18.2953"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C51AB9" />
        <stop offset="1" stopColor="#38ABF5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2842_843"
        x1="29.7086"
        y1="104.433"
        x2="80.098"
        y2="93.8553"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0504107" stopColor="#38ABF5" />
        <stop offset="1" stopColor="#C51AB9" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2842_843"
        x1="44.8476"
        y1="41.4813"
        x2="91.9381"
        y2="101.019"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2842_843"
        x1="85.5587"
        y1="57.0573"
        x2="62.6673"
        y2="57.5224"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2842_843"
        x1="69.7467"
        y1="54.9453"
        x2="90.552"
        y2="71.0013"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
