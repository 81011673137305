import useMainParticipant from "twillio/hooks/useMainParticipant/useMainParticipant";
import MainParticipantInfo from "../MainParticipantInfo/MainParticipantInfo";

import useVideoContext from "twillio/hooks/useVideoContext/useVideoContext";
import ParticipantTracks from "../ParticipantTracks/ParticipantTracks";
import useSelectedParticipant from "twillio/VideoProvider/useSelectedParticipant/useSelectedParticipant";

export default function MainParticipant() {
  const mainParticipant = useMainParticipant();
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const [selectedParticipant] = useSelectedParticipant();

  const videoPriority =
    mainParticipant === selectedParticipant &&
    mainParticipant !== localParticipant
      ? "high"
      : null;

  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo participant={mainParticipant}>
      <ParticipantTracks
        participant={mainParticipant}
        videoOnly
        enableScreenShare={mainParticipant !== localParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </MainParticipantInfo>
  );
}
