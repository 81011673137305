import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const EmailIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M14.1665 2.9165H5.83317C3.33317 2.9165 1.6665 4.1665 1.6665 7.08317V12.9165C1.6665 15.8332 3.33317 17.0832 5.83317 17.0832H14.1665C16.6665 17.0832 18.3332 15.8332 18.3332 12.9165V7.08317C18.3332 4.1665 16.6665 2.9165 14.1665 2.9165ZM14.5582 7.9915L11.9498 10.0748C11.3998 10.5165 10.6998 10.7332 9.99984 10.7332C9.29984 10.7332 8.5915 10.5165 8.04984 10.0748L5.4415 7.9915C5.17484 7.77484 5.13317 7.37484 5.3415 7.10817C5.55817 6.8415 5.94984 6.7915 6.2165 7.00817L8.82484 9.0915C9.45817 9.59984 10.5332 9.59984 11.1665 9.0915L13.7748 7.00817C14.0415 6.7915 14.4415 6.83317 14.6498 7.10817C14.8665 7.37484 14.8248 7.77484 14.5582 7.9915Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
  </svg>
);
