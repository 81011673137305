// Lib
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
import { useLazyGetCurrentConsultationCheckQuery } from "rtk/query/consultationAPI";
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Description, Title } from "./styled";

export const Join: FC = () => {
  const navigate = useNavigate();

  const { id, name, token } = useParams();

  const [check] = useLazyGetCurrentConsultationCheckQuery();

  const isHaveAllVariables = !!id && !!name && !!token;

  if (!isHaveAllVariables) {
    navigate(`/home`);
  }

  const handleJoin = async () => {
    try {
      const { status } = await check().unwrap();

      if (status === "expired") {
        navigate(`/expired/`);
        return;
      }

      if (isHaveAllVariables) {
        navigate(`/session/${id}/${name}/${token}`);
      }
    } catch (error) {
      console.log(error);
      navigate(`/home`);
    }
  };

  return (
    <FlexContainer $fullwidth $justify="center">
      <FlexContainer
        $fullwidth
        $column
        $width="361px"
        $align="center"
        $justify="center"
        $padding="80px 20px"
      >
        <FlexContainer $fullwidth $column $padding="0 0 91px 0">
          <Title>You&apos;re all set</Title>

          <Description>
            Click on the &quot;Join&quot; button to begin
          </Description>
        </FlexContainer>

        <Button.Join onClick={handleJoin}>Join</Button.Join>
      </FlexContainer>
    </FlexContainer>
  );
};
