// Lib
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
// Hooks
import useRoomState from "twillio/hooks/useRoomState/useRoomState";
import useVideoContext from "twillio/hooks/useVideoContext/useVideoContext";
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
import MediaErrorSnackbar from "twillio/components/MediaErrorSnackbar/MediaErrorSnackbar";
import { ReconnectingNotification } from "../../../../twillio/components/ReconnectingNotification";
import { RecordingNotifications } from "../../../../twillio/components/RecordingNotifications";
import Room from "../Room";
import MenuBar from "../MenuBar/MenuBar";
import { Padding, RoomWrapper, Wrapper } from "./styled";
// Styled

export const Call: FC = () => {
  const navigate = useNavigate();
  const { id, name, token } = useParams();
  const [mediaError, setMediaError] = useState<Error>();

  const [isReady, setIsReady] = useState(false);

  const roomState = useRoomState();

  const {
    connect: videoConnect,
    isConnecting,
    getAudioAndVideoTracks,
  } = useVideoContext();

  const handleStart = async () => {
    getAudioAndVideoTracks()
      .catch(error => {
        console.log("Error acquiring local media");
        setMediaError(error);
        navigate(`/permissions_denied/${id}/${name}/${token}`);
      })
      .then(() => setIsReady(true));
  };

  useEffect(() => {
    if (!isReady) return;
    videoConnect(token, name);
  }, [isReady]);

  useEffect(() => {
    if (id && name && token) handleStart();
  }, []);

  return (
    <>
      <MediaErrorSnackbar error={mediaError} />
      <Wrapper>
        {isConnecting && <div>Connecting...</div>}
        {roomState === "disconnected" ? null : isReady ? (
          <>
            <Padding />
            <RoomWrapper>
              <ReconnectingNotification />
              <RecordingNotifications />
              <Room />
            </RoomWrapper>
            <MenuBar />
          </>
        ) : null}
      </Wrapper>
    </>
  );
};
