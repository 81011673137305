import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

const schema = object()
  .shape({
    email: string().email("Email not valid").required("Email is required"),
    password: string().trim().required("Password is required"),
  })
  .required();

export const resolver = yupResolver(schema);
