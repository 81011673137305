import styled from "styled-components";
import { theme } from "theme";

interface FlexContainerProps {
  $column?: boolean;
  $wrap?: boolean;
  $gap?: number;
  $fullwidth?: boolean;
  $align?: "center" | "flex-start" | "flex-end";
  $justify?: "center" | "flex-start" | "flex-end" | "space-between";
  $padding?: string;
  $grow?: number;
  $margin?: string;
  $cursor?: "pointer" | "move";
  $width?: string;
  $height?: string;
}

export const Block = styled.div<{ $padding?: string }>`
  width: 100%;
  ${({ $padding }) => $padding && `padding: ${$padding};`}
`;

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;

  ${({ $align }) => $align && `align-items: ${$align};`}
  ${({ $justify }) => $justify && `justify-content: ${$justify};`}
  ${({ $column }) => $column && `flex-direction: column;`}
  ${({ $gap }) => $gap && `gap: ${$gap}px;`}
  ${({ $fullwidth }) => $fullwidth && `width: 100%;`}
  ${({ $padding }) => $padding && `padding: ${$padding};`}
  ${({ $margin }) => $margin && `margin: ${$margin};`}
  ${({ $grow }) => $grow && `flex-grow: ${$grow};`}
  ${({ $width }) => $width && `width: ${$width};`}
  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $cursor }) => $cursor && `cursor: ${$cursor};`}
  ${({ $wrap }) => $wrap && `flex-wrap: wrap;`}
`;

export const HorizontalDevider = styled.div<{
  $width?: string;
  $margin?: string;
}>`
  height: 2px;
  width: ${({ $width }) => ($width ? $width : `100%`)};
  background: ${theme.color.label.quaternary};
  ${({ $margin }) => $margin && `margin: ${$margin};`}
`;

export const DottedSeparator = styled.div`
  width: 100%;
  border-bottom: 1px dotted rgba(235, 235, 245, 0.18);
`;

export const Info = styled.div`
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
`;
