// Lib
import { FC } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
import { Badge } from "components";
// Styled
import { Container, Title } from "./styled";
import { DottedSeparator, FlexContainer, Info } from "styled/Box";
import { Typography } from "styled/Typography";
import { UserProfileData } from "types/consultation";
import { getGenderIcon } from "./helpers";

interface ProfileDataProps {
  data: UserProfileData;
}

export const ProfileData: FC<ProfileDataProps> = ({ data }) => {
  return (
    <Container>
      <FlexContainer
        $fullwidth
        $align="center"
        $justify="space-between"
        $padding="12px 16px"
        $gap={8}
      >
        <Typography.Body1Semibold>
          {data?.name || null}
        </Typography.Body1Semibold>

        <FlexContainer $align="center" $gap={8}>
          <Info>
            {getGenderIcon(data?.gender)}
            {data?.gender || null}
          </Info>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        $align="center"
        $justify="space-between"
        $padding="8px 16px"
      >
        <Title>Preferred Language</Title>

        <Typography.Body1Semibold>
          {data?.language?.join(", ") || null}
        </Typography.Body1Semibold>
      </FlexContainer>

      {!!data?.industries?.length && (
        <>
          <DottedSeparator />

          <FlexContainer $fullwidth $column $padding="12px 16px 4px">
            <Title $padding="0 0 4px 0">Involved Industry</Title>
            <FlexContainer $fullwidth $wrap>
              {data.industries.map(i => (
                <Badge key={i} title={i} />
              ))}
            </FlexContainer>
          </FlexContainer>
        </>
      )}
      {!!data?.ethnicity && (
        <>
          <DottedSeparator />

          <FlexContainer $fullwidth $column $padding="12px 16px">
            <Title $padding="0 0 4px 0">What&apos;s your ethnicity?</Title>
            <Typography.Body1Semibold>
              {data.ethnicity}
            </Typography.Body1Semibold>
          </FlexContainer>
        </>
      )}
    </Container>
  );
};
