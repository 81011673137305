import styled from "styled-components";

export const Title = styled.span<{ $isLocal?: boolean }>`
  padding-right: 10px;
  @media (max-width: 1200px) {
    ${({ $isLocal }) => $isLocal && `display: none`}
  }
`;

export const Avatar = styled.div<{ $isLocalParticipant?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    color: #fff;

    text-align: center;
    font-family: Poppins;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 80px;
  }

  @media (max-width: 1200px) {
    ${({ $isLocalParticipant }) =>
      $isLocalParticipant &&
      `width: 80px;
  height: 80px;
  
  div {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }
  `}
  }
`;

export const ParticipantWrapper = styled.div<{ $hide?: boolean }>`
  position: relative;
  height: 100%;

  ${({ $hide }) => $hide && `display: none;`}
`;
