// Lib
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
import { GlassDangerIcon } from "icons";
// Layouts
// Components
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Description, ExpiredTitle } from "./styled";

export const Disconnected: FC = () => {
  const { id, name, token } = useParams();
  const navigate = useNavigate();

  const handleTryToReconnect = () => {
    navigate(`/session/${id}/${name}/${token}`);
  };

  return (
    <FlexContainer $fullwidth $justify="center">
      <FlexContainer
        $fullwidth
        $column
        $width="361px"
        $align="center"
        $justify="center"
        $padding="80px 20px"
      >
        <GlassDangerIcon />

        <ExpiredTitle>The Internet has disappeared</ExpiredTitle>

        <Description>Check your connection and try again</Description>

        <Button.Base onClick={handleTryToReconnect}>Try again</Button.Base>
      </FlexContainer>
    </FlexContainer>
  );
};
