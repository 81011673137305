// Lib
import { createApi } from "@reduxjs/toolkit/query/react";
// Types
import {
  RetrieveActiveConsultation,
  TakeAwaysPayload,
} from "types/consultation";
// Utils
import { baseQuery } from "utils/baseQuery";

import { endpoints } from "consts";

export const consultationAPI = createApi({
  reducerPath: "consultationAPI",
  baseQuery: baseQuery(),

  endpoints: build => ({
    getCurrentConsultation: build.query<RetrieveActiveConsultation, void>({
      query: () => ({
        url: endpoints.currentConsultation,
        method: "GET",
      }),
    }),
    getCurrentConsultationCheck: build.query<{ status: string }, void>({
      query: () => ({
        url: endpoints.currentConsultationCheck,
        method: "GET",
      }),
    }),
    startConsultation: build.query<void, void>({
      query: () => ({
        url: endpoints.startConsultation,
        method: "PATCH",
      }),
    }),
    finishConsultation: build.query<void, void>({
      query: () => ({
        url: endpoints.finishConsultation,
        method: "PATCH",
      }),
    }),
    getCurrentConsultationHome: build.query<RetrieveActiveConsultation, void>({
      query: () => ({
        url: endpoints.currentConsultation,
        method: "GET",
      }),
      keepUnusedDataFor: 0.0001,
    }),
    sendTakeAways: build.query<void, TakeAwaysPayload>({
      query: ({ id, ...data }) => ({
        url: `api/consultations/${id}/takeaways/`,
        method: "PATCH",
        data,
      }),
    }),
  }),
});

export const {
  useGetCurrentConsultationHomeQuery,
  useGetCurrentConsultationQuery,
  useGetCurrentConsultationCheckQuery,
  useLazyGetCurrentConsultationQuery,
  useLazyGetCurrentConsultationCheckQuery,
  useLazyStartConsultationQuery,
  useLazyFinishConsultationQuery,
  useLazySendTakeAwaysQuery,
} = consultationAPI;
