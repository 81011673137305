// Lib
import { FC } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled
import { Wrapper } from "./styled";

interface TextEditorProps {
  placeholder: string;
  onChange: (data: string) => void;
}

export const TextEditor: FC<TextEditorProps> = ({ placeholder, onChange }) => {
  const onEditorStateChange = (editorState: EditorState) => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <Wrapper>
      <Editor
        placeholder={placeholder}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ["inline", "textAlign", "list"],
        }}
      />
    </Wrapper>
  );
};
