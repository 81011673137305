import { styled } from "styled-components";

export const Container = styled.div<{
  $paginatedParticipantsCount: number;
  $isVideo?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ $paginatedParticipantsCount, $isVideo }) =>
    !$isVideo ? "1fr" : $paginatedParticipantsCount === 1 ? "1fr" : "1fr 1fr"};
  height: calc(92vh - 116px);

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    height: calc(100vh - 116px);
  }
`;

export const NotificationContainer = styled.div`
  border-radius: 16px;
  z-index: 1000;
  max-width: 380px;
  width: 100%;
  text-align: center;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(42, 42, 42, 1);
`;

// export const ParticipantContainer = styled.div<{
//   $index?: number;
// }>`
//   margin: 20px;
//   border-radius: 16px;
//   overflow: hidden;
// `;

export const ParticipantContainer = styled.div<{
  $index?: number;
  $hide?: boolean;
}>`
  margin: 20px;
  border-radius: 16px;
  overflow: hidden;

  ${({ $hide, $index }) => $index === 0 && $hide && `display: none;`}

  @media (max-width: 1200px) {
    ${({ $index }) =>
      $index === 0 &&
      `position: absolute; top: 30px; right: 0; height: 200px; width: 150px; z-index: 500; border: 1px solid grey; margin: 30px;`}
  }
`;
// export const ParticipantContainer = styled.div<{
//   $index?: number;
//   $hide: boolean;
// }>`
//   margin: 20px;
//   border-radius: 16px;
//   overflow: hidden;

//   @media (max-width: 1200px) {
//     ${({ $index, $hide }) =>
//       $index === 0 &&
//       $hide &&
//       `position: absolute; top: 30px; right: 0; height: 200px; width: 150px; z-index: 500; border: 1px solid grey; margin: 30px;`}
//   }
//   @media (max-width: 1200px) {
//     ${({ $index, $hide }) =>
//       $index === 0 && !$hide && `position: absolute; display: none;`}
//   }
// `;
