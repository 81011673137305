import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const MaleIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 48 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M45.5625 0.75C46.8828 0.75 48 1.86719 48 3.1875V14.5625C48 15.9844 46.8828 17 45.5625 17C44.1406 17 43.125 15.9844 43.125 14.5625V9.07812L34.4922 17.7109C39.875 24.7188 39.3672 34.6719 32.9688 41.0703C29.4141 44.5234 24.9453 46.25 20.375 46.25C15.7031 46.25 11.1328 44.5234 7.67969 41.0703C0.671875 34.0625 0.671875 22.7891 7.67969 15.7812C11.1328 12.3281 15.7031 10.5 20.375 10.5C24.1328 10.5 27.8906 11.8203 31.0391 14.2578L39.6719 5.625H34.1875C32.7656 5.625 31.75 4.60938 31.75 3.1875C31.75 1.86719 32.7656 0.75 34.1875 0.75H45.5625ZM29.5156 37.6172C34.5938 32.5391 34.5938 24.3125 29.5156 19.2344C27.0781 16.7969 23.8281 15.375 20.375 15.375C16.8203 15.375 13.5703 16.7969 11.1328 19.2344C6.05469 24.3125 6.05469 32.5391 11.1328 37.6172C13.5703 40.0547 16.8203 41.375 20.375 41.375C23.8281 41.375 27.0781 40.0547 29.5156 37.6172Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
  </svg>
);
