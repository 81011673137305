import { ParticipantAudioTracks } from "twillio/components/ParticipantAudioTracks/ParticipantAudioTracks";

import { useAppState } from "twillio/StateProvider";
import { GalleryView } from "twillio/components/GalleryView/GalleryView";
import MainParticipant from "twillio/components/MainParticipant/MainParticipant";

/**
 * This hook turns on speaker view when screensharing is active, regardless of if the
 * user was already using speaker view or gallery view. Once screensharing has ended, the user's
 * view will return to whatever they were using prior to screenshare starting.
 */

export default function Room() {
  const { isGalleryViewActive } = useAppState();

  // Here we switch to speaker view when a participant starts sharing their screen, but
  // the user is still free to switch back to gallery view.

  return (
    <div>
      {/* 
        This ParticipantAudioTracks component will render the audio track for all participants in the room.
        It is in a separate component so that the audio tracks will always be rendered, and that they will never be 
        unnecessarily unmounted/mounted as the user switches between Gallery View and speaker View.
      */}
      <ParticipantAudioTracks />

      {isGalleryViewActive ? (
        <GalleryView />
      ) : (
        <>
          <MainParticipant />
        </>
      )}
    </div>
  );
}
