import React from "react";

import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";

import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";

import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import { Avatar, ParticipantWrapper, Title } from "./styled";
import useIsTrackEnabled from "twillio/hooks/useIsTrackEnabled/useIsTrackEnabled";

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  children,
  isLocalParticipant,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === "audio");
  const videoPublication = publications.find(
    p => !p.trackName.includes("screen") && p.kind === "video",
  );

  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack,
  );

  const isVideoTrackEnabled = useIsTrackEnabled(
    videoTrack as LocalVideoTrack | RemoteVideoTrack,
  );

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const isVideo = window.sessionStorage.getItem("type") === "video";

  return (
    <ParticipantWrapper
      onClick={onClick}
      data-cy-participant={participant.identity}
      // $hide={!isVideo && isLocalParticipant}
    >
      <div style={{ position: "absolute", bottom: 0, right: 0, zIndex: 200 }}>
        <NetworkQualityLevel participant={participant} />
        <div>
          <span style={{ display: "flex", alignItems: "center" }}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Title $isLocal={isLocalParticipant}>
              {participant.identity}
              {isLocalParticipant && " (You)"}
            </Title>
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          background: "black",
        }}
      >
        {(!isVideoTrackEnabled || !isVideoEnabled || isVideoSwitchedOff) && (
          <Avatar $isLocalParticipant={isLocalParticipant}>
            <div>{participant.identity.slice(0, 2).toUpperCase()}</div>
          </Avatar>
        )}
        {isParticipantReconnecting && (
          <div style={{ position: "absolute" }}>
            <div>Reconnecting...</div>
          </div>
        )}

        {children}
      </div>
    </ParticipantWrapper>
  );
}
