import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const LogoHeaderIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="95"
    height="23"
    viewBox="0 0 95 23"
    fill="none"
    {...props}
    style={{ cursor: "pointer" }}
  >
    <path
      d="M3.41644 21.834C2.38884 21.1779 1.59494 20.2614 1.03437 19.0841C0.474133 17.9072 0.193848 16.5373 0.193848 14.975C0.193848 13.4335 0.474133 12.0742 1.03437 10.897C1.59494 9.72009 2.38884 8.80858 3.41644 8.16241C4.44403 7.51692 5.64283 7.19385 7.01284 7.19385C8.38284 7.19385 9.58164 7.51692 10.6092 8.16241C11.6368 8.80858 12.4307 9.72009 12.9913 10.897C13.5519 12.0742 13.8322 13.4335 13.8322 14.975C13.8322 16.5164 13.5519 17.881 12.9913 19.0686C12.4307 20.2561 11.6368 21.1779 10.6092 21.834C9.58164 22.4904 8.38284 22.8185 7.01284 22.8185C5.64283 22.8185 4.44403 22.4904 3.41644 21.834ZM9.6441 18.6466C10.2771 17.7613 10.5937 16.5373 10.5937 14.975C10.5937 13.4544 10.2823 12.2513 9.65964 11.3657C9.03694 10.4804 8.15445 10.0375 7.01281 10.0375C5.892 10.0375 5.02011 10.4857 4.39741 11.3813C3.77472 12.2772 3.46303 13.475 3.46303 14.975C3.46303 16.5167 3.77472 17.7354 4.39741 18.631C5.02011 19.527 5.89203 19.9748 7.01281 19.9748C8.1336 19.9748 9.0105 19.5323 9.6441 18.6466Z"
      fill="white"
    />
    <path
      d="M27.7657 8.75622C28.6892 9.79844 29.1516 11.2045 29.1516 12.9749V22.5373H25.9442V13.2249C25.9442 12.2046 25.6999 11.4178 25.2124 10.8655C24.7246 10.3139 24.0238 10.0376 23.1106 10.0376C22.1974 10.0376 21.4653 10.3968 20.9153 11.1156C20.3653 11.8344 20.0903 12.7874 20.0903 13.9749V22.5372H16.8833V11.5999C16.8833 11.0795 16.7845 10.7046 16.5875 10.4751C16.3898 10.2459 16.0629 10.1311 15.6065 10.1311C15.3986 10.1311 15.233 10.1421 15.1084 10.1626V7.47514C15.5857 7.39222 16.0629 7.35008 16.5406 7.35008C18.2222 7.35008 19.2184 8.0586 19.5298 9.47498C20.0282 8.7459 20.6714 8.18365 21.4604 7.7876C22.249 7.39222 23.1107 7.19385 24.0447 7.19385C25.6018 7.19385 26.8419 7.71467 27.7657 8.75622Z"
      fill="white"
    />
    <path
      d="M46.4944 12.5999L48.0511 7.4751H51.5074V7.56865L46.6189 22.6934H45.3422L41.2632 13.3811L37.1842 22.6934H35.9075L31.019 7.56865V7.4751H34.4439L36.001 12.5999L36.9972 16.5373L40.6405 7.97497H41.8548L45.5289 16.5061L46.4944 12.5999Z"
      fill="white"
    />
    <path
      d="M64.5236 18.3186C64.5236 19.0059 64.6532 19.449 64.9129 19.6467C65.1721 19.8448 65.6338 19.9124 66.2985 19.8497V22.5372C65.8625 22.6414 65.3747 22.6934 64.8349 22.6934C64.046 22.6934 63.3972 22.5216 62.8888 22.178C62.3798 21.834 62.0529 21.3291 61.9081 20.6624C61.1189 22.0997 59.6765 22.8185 57.58 22.8185C56.0642 22.8185 54.8654 22.3966 53.9836 21.5527C53.1007 20.7092 52.6602 19.5479 52.6602 18.0685C52.6602 16.6106 53.1477 15.4543 54.1237 14.5998C55.0991 13.746 56.3967 13.3188 58.0159 13.3188C59.1367 13.3188 60.2367 13.5271 61.3165 13.9437V13.0998C61.3165 12.0168 61.0984 11.2406 60.6624 10.7719C60.2265 10.3032 59.4686 10.0687 58.3894 10.0687C57.0607 10.0687 55.6805 10.4021 54.248 11.0688L53.5321 8.44371C55.151 7.6108 56.8327 7.19385 58.5762 7.19385C60.5068 7.19385 61.9806 7.69372 62.9979 8.69382C64.0146 9.69393 64.5236 11.1624 64.5236 13.0999V18.3186ZM60.4912 19.178C61.0409 18.5637 61.3165 17.7457 61.3165 16.7247V16.131C60.4446 15.8606 59.5205 15.725 58.5451 15.725C57.7145 15.725 57.071 15.9177 56.6145 16.3031C56.1577 16.6886 55.9297 17.2458 55.9297 17.9749C55.9297 18.6622 56.1267 19.1886 56.5213 19.5529C56.9153 19.9177 57.4759 20.0999 58.2027 20.0999C59.178 20.0999 59.9409 19.7927 60.4912 19.178Z"
      fill="white"
    />
    <path
      d="M78.8469 8.19398L77.6326 10.8811C77.2591 10.6937 76.9058 10.5531 76.5739 10.4595C76.2417 10.3656 75.858 10.3189 75.4221 10.3189C74.446 10.3189 73.6627 10.642 73.0711 11.2874C72.4794 11.9336 72.1836 12.8607 72.1836 14.0688V22.5372H68.9766V11.5999C68.9766 11.0586 68.8724 10.6781 68.6649 10.4595C68.4573 10.2406 68.146 10.1311 67.7308 10.1311C67.5021 10.1311 67.3263 10.1421 67.2017 10.1626V7.47514C67.6376 7.39222 68.1046 7.35008 68.6027 7.35008C70.2841 7.35008 71.2806 8.04832 71.592 9.44381C72.0071 8.71472 72.5829 8.1578 73.3202 7.77202C74.0566 7.38657 74.8304 7.19385 75.6398 7.19385C76.8852 7.19385 77.9542 7.52724 78.8469 8.19398Z"
      fill="white"
    />
    <path
      d="M93.1713 18.413C93.1713 18.9546 93.2595 19.3348 93.436 19.5534C93.6122 19.7723 93.9186 19.8814 94.3545 19.8814C94.5829 19.8814 94.7697 19.8712 94.9151 19.8503V22.5377C94.4792 22.6207 94.0224 22.6628 93.5451 22.6628C92.6524 22.6628 91.9672 22.4548 91.4899 22.0379C91.0123 21.6213 90.7218 20.9754 90.618 20.1004C90.1199 20.9963 89.4708 21.6737 88.6719 22.1314C87.8727 22.5895 87.0163 22.819 86.1031 22.819C84.8157 22.819 83.7157 22.4704 82.8025 21.7722C81.8889 21.0743 81.1935 20.1315 80.7162 18.944C80.2387 17.7565 80 16.444 80 15.0067C80 13.5485 80.2542 12.2254 80.7628 11.0378C81.2715 9.85067 82.0188 8.91294 83.0048 8.22564C83.9907 7.53803 85.169 7.19436 86.539 7.19436C87.8469 7.19436 88.9882 7.48626 89.9642 8.06941V0.663086H93.1712L93.1713 18.413ZM89.0768 18.6316C89.6684 17.736 89.9642 16.5276 89.9642 15.0067V10.8816C89.0923 10.3194 88.0852 10.0381 86.9439 10.0381C85.802 10.0381 84.9043 10.4706 84.2505 11.3347C83.5964 12.1995 83.2695 13.4238 83.2695 15.0067C83.2695 16.5485 83.5601 17.7618 84.1415 18.6472C84.7225 19.5328 85.5425 19.9753 86.6012 19.9753C87.6599 19.9753 88.4852 19.5275 89.0768 18.6316Z"
      fill="white"
    />
  </svg>
);
