// Lib
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { useGetCurrentConsultationHomeQuery } from "rtk/query/consultationAPI";
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
import { GlassDiscoveryIcon } from "icons";
// Layouts
// Components
import { Modal } from "components";
// Styled
import { Typography } from "styled/Typography";
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Description, Title } from "./styled";

export const Home: FC = () => {
  const navigate = useNavigate();
  const [isAvaible, setIsAvaible] = useState(false);
  const { data, isFetching } = useGetCurrentConsultationHomeQuery(null, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 20000,
    skip: !isAvaible,
  });

  useEffect(() => {
    if (isAvaible && !isFetching && data?.room_sid) {
      if (data?.consultation_request?.status === "started") {
        navigate(
          `/join/${data?.consultation_request?.id}/${data?.room_sid}/${data?.counselor_access_token}`,
        );
        return;
      }
      navigate(`/consultation/${data?.room_sid}`);
    }
  }, [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // to prevent unnecessary redirects
      setIsAvaible(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const [suicidalModal, setSuicidalModal] = useState(false);

  return (
    <>
      <FlexContainer $fullwidth $justify="center">
        <FlexContainer
          $fullwidth
          $column
          $width="361px"
          $align="center"
          $justify="center"
          $padding="80px 20px"
        >
          <GlassDiscoveryIcon />

          <Title>We&apos;re currently awaiting incoming requests</Title>

          <Description>
            You will receive a notification when a matched request is available.
          </Description>

          <Button.Transparent onClick={() => setSuicidalModal(true)}>
            Read suicidal prevention guidelines
          </Button.Transparent>
        </FlexContainer>
      </FlexContainer>

      <Modal
        open={suicidalModal}
        title="Important Notice"
        onClose={() => setSuicidalModal(false)}
      >
        <FlexContainer $fullwidth $column>
          <Typography.Body2Regular $padding="0 0 24px">
            <br />
            If you have thoughts of suicide or of harming yourself or others, or
            if you feel that other person may have such thoughts, you must
            immediately contact the National Suicide Prevention Hotline at
            1-800-273-8255 or 988. If you feel that you or someone may be in
            danger or if you or someone has any medical emergency, you must call
            911 immediately or other relevant emergency service numbers, notify
            the relevant authorities, and seek immediate in-person assistance.
            Onward is not a medical service or crisis hotline. Onward displays
            the suicide hotline in several prominent locations on the website or
            in the app for mental health emergencies which we implore all users
            of this site to use when faced with an immediate or emergent crisis.
            <br />
            <br />
            If you’re experiencing severe emotional distress, the resources
            below provide free and confidential support 24/7.
            <br />
            <br />
            If this is an emergency, call 911.
            <br />
            Suicide Prevention Hotline: 1-800-273-8255; or Call 988
            <br />
            Crisis Text Line: Text HOME to 741741
          </Typography.Body2Regular>
          <Button.Base onClick={() => setSuicidalModal(false)}>
            Close
          </Button.Base>
        </FlexContainer>
      </Modal>
    </>
  );
};
