// Lib
import { FC } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled
import { DottedSeparator, FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Answer, Container, Question } from "./styled";
import { MentalHealthBackground } from "types/consultation";
interface QuestionGroupProps {
  data: MentalHealthBackground;
}

export const QuestionGroup: FC<QuestionGroupProps> = ({ data }) => {
  return (
    <Container>
      <FlexContainer $padding="12px 16px">
        <Typography.Body1Semibold>
          Mental Health Background
        </Typography.Body1Semibold>
      </FlexContainer>

      {!!data.diagnosedBefore && (
        <>
          <FlexContainer $column $padding="8px 16px">
            <Question $padding="0 0 4px 0">
              Have you been diagnosed with a mental illness or receiving
              long-term mental health treatment before?
            </Question>

            <Answer>{data.diagnosedBefore}</Answer>
          </FlexContainer>

          <DottedSeparator />
        </>
      )}

      {data?.lastTime && (
        <>
          <FlexContainer $column $padding="8px 16px">
            <Question $padding="0 0 4px 0">
              When was the last time you had?
            </Question>
            <Answer>{data.lastTime}</Answer>
          </FlexContainer>

          <DottedSeparator />
        </>
      )}

      {data?.medications && (
        <>
          <FlexContainer $column $padding="8px 16px">
            <Question $padding="0 0 4px 0">
              Did you take any medication?
            </Question>

            <Answer>{data.medications}</Answer>
          </FlexContainer>

          <DottedSeparator />
        </>
      )}

      {data?.hospitalizedBefore && (
        <>
          <FlexContainer $column $padding="8px 16px">
            <Question $padding="0 0 4px 0">
              Were you hospitalized before?
            </Question>

            <Answer>{data.hospitalizedBefore}</Answer>
          </FlexContainer>

          <DottedSeparator />
        </>
      )}

      {data?.treatmentDuration && (
        <>
          <FlexContainer $column $padding="8px 16px">
            <Question $padding="0 0 4px 0">
              What was the duration of the treatment?
            </Question>
            <Answer>{data.treatmentDuration}</Answer>
          </FlexContainer>

          <DottedSeparator />
        </>
      )}

      {data?.fullyRecoveredSince && (
        <>
          <FlexContainer $column $padding="8px 16px">
            <Question $padding="0 0 4px 0">
              Have you fully recovered since then?
            </Question>
            <Answer>{data?.fullyRecoveredSince}</Answer>
          </FlexContainer>

          <DottedSeparator />
        </>
      )}

      {data?.currentlyTakingAnyMedication && (
        <>
          <FlexContainer $column $padding="8px 16px">
            <Question $padding="0 0 4px 0">
              Are you currently taking any medication or receiving a long-term
              treatment plan?
            </Question>

            <Answer>{data.currentlyTakingAnyMedication}</Answer>
          </FlexContainer>

          <DottedSeparator />
        </>
      )}

      {data?.haveSeenCounselorBefore && (
        <FlexContainer $column $padding="8px 16px">
          <Question $padding="0 0 4px 0">
            Have you seen a councelor before?
          </Question>

          <Answer>{data.haveSeenCounselorBefore}</Answer>
        </FlexContainer>
      )}
    </Container>
  );
};
