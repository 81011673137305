// Lib
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled

import { forwardRef } from "react";
import { ControllerFieldState } from "react-hook-form";
import { InputProps as AntInputProps, InputRef } from "antd";
import { StyledInput } from "./styled";
import { EyeVisibleIcon, EyeInvisibleIcon } from "icons";

interface InputProps extends AntInputProps {
  fieldState?: ControllerFieldState;
  fieldError?: boolean | string;
}

export const InputPassword = forwardRef<InputRef, InputProps>(
  ({ fieldError, fieldState, ...props }, ref) => {
    const error = fieldError || fieldState?.error?.message || false;

    return (
      <StyledInput
        ref={ref}
        placeholder="Password"
        iconRender={visible =>
          visible ? <EyeInvisibleIcon /> : <EyeVisibleIcon />
        }
        {...props}
        $isError={!!error}
      />
    );
  },
);

InputPassword.displayName = "InputPassword";
