// Lib
import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Api
// Hooks
// Actions
import { userLogout } from "rtk/slices";
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
import { LogoHeaderIcon, LogoutIcon } from "icons";
// Layouts
// Components
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { useAppDispatch } from "hooks";

export const Header: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  //scroll to top after change route
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleLogout = () => dispatch(userLogout());

  if (pathname.includes("session")) {
    return null;
  }

  return (
    <FlexContainer
      $fullwidth
      $align="center"
      $justify="space-between"
      $padding="24px 40px"
    >
      <LogoHeaderIcon onClick={() => navigate("/home")} />

      <Button.IconTransparent onClick={handleLogout}>
        <LogoutIcon />
      </Button.IconTransparent>
    </FlexContainer>
  );
};
