import { styled } from "styled-components";
import { Typography } from "styled/Typography";

export const Info = styled.div<{ $margin?: string }>`
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */

  ${({ $margin }) => $margin && `margin: ${$margin};`}
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 756px;
  padding: 0 16px;
`;

export const Title = styled(Typography.H5)`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 100%;
  padding-bottom: 8px;
`;

export const Space = styled.div`
  height: 16px;
`;

export const Container = styled.div`
  width: 100%;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
`;
