import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 5px;
  font-family: "Poppins";
  .rdw-editor-toolbar {
    border: none;

    .rdw-option-wrapper {
      border: none;
    }

    .rdw-option-wrapper[title="Strikethrough"] {
      display: none;
    }

    .rdw-option-wrapper[title="Monospace"] {
      display: none;
    }

    .rdw-option-wrapper[title="Superscript"] {
      display: none;
    }
    .rdw-option-wrapper[title="Subscript"] {
      display: none;
    }
  }

  .rdw-editor-main {
    height: 220px;
  }

  .public-DraftStyleDefault-listLTR {
    margin-left: 27px;
  }
`;
