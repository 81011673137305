// Lib
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import { useLazyLoginQuery } from "rtk/query/loginAPI";
import { useLazyGetCurrentConsultationQuery } from "rtk/query/consultationAPI";
// Hooks
import { useAppDispatch, useAppSelector } from "hooks";
// Actions
import { userLogin } from "rtk/slices";
// Selectors
import { isAuth } from "rtk/selectors";
// Types
// Theme
// Constants
// Helpers
// Utils
import { token } from "utils/handleToken";
// Icons
import {
  EmailIcon,
  GlassClockIcon,
  LockIcon,
  LogoIcon,
  WarningIcon,
} from "icons";
// Layouts
// Components
import { Input, InputPassword } from "components";
// Styled
import { FlexContainer, HorizontalDevider } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { Description, ErrorContainer, Link, Wrapper } from "./styled";

import { resolver } from "./validation";
import { RegistrationStatus } from "types/auth";
import { ConsultationStatus } from "types/consultation";

interface LocationState {
  from?: {
    pathname?: string;
  };
}

interface LoginFormTypes {
  email?: string;
  password?: string;
}

type AppStatusType = "pending" | "deactivated" | "login";

export const Login: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthorized = useAppSelector(isAuth);

  //react-router-dom don`t have this interface
  const from =
    (location.state as LocationState)?.from?.pathname || "/consultation";

  useEffect(() => {
    if (!isAuthorized) return;

    const access = token.access.get();

    if (!access) return;

    navigate(from, { replace: true });
  }, []);

  const [login] = useLazyLoginQuery();

  const [check] = useLazyGetCurrentConsultationQuery();

  const [appStatus, setAppStatus] = useState<AppStatusType>("login");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormTypes>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver,
  });

  useEffect(() => {
    if (errors) {
      const message = Object.keys(errors)
        .map(key => errors[key]?.message || "")
        .join("; ");
      setErrorMessage(message);
    }
  }, [errors]);

  const consultationCheck = async (): Promise<
    | { status: ConsultationStatus; id: number; name: string; token: string }
    | false
  > => {
    try {
      const currentconsultation = await check().unwrap();

      // if (currentconsultation?.consultation_request?.id) {
      //   return currentconsultation?.consultation_request?.id?.toString();
      // }
      if (currentconsultation?.consultation_request?.id) {
        return {
          status: currentconsultation?.consultation_request?.status,
          id: currentconsultation?.consultation_request?.id,
          name: currentconsultation?.room_sid,
          token: currentconsultation?.counselor_access_token,
        };
      }
    } catch (error) {
      return false;
    }
  };

  const onSubmit: SubmitHandler<LoginFormTypes> = async ({
    email,
    password,
  }) => {
    setIsLoading(true);

    try {
      const data = await login({ email, password }).unwrap();

      const { is_active, access_token, registration_process_status } =
        data || {};

      if (
        registration_process_status === RegistrationStatus.DeactivatedByAdmin
      ) {
        setAppStatus("deactivated");
        return;
      }

      if (!is_active) {
        setAppStatus("pending");
        return;
      }

      if (access_token) {
        token.access.set(access_token, "localStorage");

        const consultation = await consultationCheck();

        dispatch(userLogin());

        if (consultation) {
          if (consultation.status === "started") {
            if (consultation?.id && consultation?.name && consultation?.token)
              navigate(
                `/join/${consultation?.id}/${consultation?.name}/${consultation?.token}`,
                {
                  replace: true,
                },
              );
            return;
          }

          if (consultation.status === "accepted") {
            navigate(`/consultation/${consultation?.id}`, {
              replace: true,
            });
            return;
          }
        }

        navigate("/home", { replace: true });
      }
    } catch (error) {
      if (error?.status === 400) {
        setErrorMessage(
          "Invalid email or password. Please check and try again",
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <FlexContainer $padding="0 0 40px">
        <LogoIcon />
      </FlexContainer>

      {appStatus === "login" && (
        <>
          <HorizontalDevider $width="123px" />

          <FlexContainer $fullwidth $column $padding="40px 0">
            <FlexContainer $fullwidth $padding="0 0 8px 0">
              <Controller
                name={"email"}
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    prefix={<EmailIcon />}
                    placeholder="Email"
                    value={"sdsds"}
                    disabled={isLoading}
                    {...field}
                    fieldState={fieldState}
                  />
                )}
              />
            </FlexContainer>

            <Controller
              name={"password"}
              control={control}
              render={({ field, fieldState }) => (
                <InputPassword
                  prefix={<LockIcon />}
                  disabled={isLoading}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          </FlexContainer>

          <Button.Base htmlType="submit" loading={isLoading}>
            Sign In
          </Button.Base>

          {errorMessage && (
            <ErrorContainer>
              <WarningIcon />
              <span>{errorMessage}</span>
            </ErrorContainer>
          )}
        </>
      )}

      {appStatus === "pending" && (
        <>
          <div />

          <FlexContainer $padding="40px 0">
            <GlassClockIcon />
          </FlexContainer>

          <Typography.H4 $padding="0 0 40px">Pending</Typography.H4>

          <Description>
            Your sign-up application is currently under review. Our
            representative will reach out to you via email shortly.
          </Description>
        </>
      )}

      {appStatus === "deactivated" && (
        <>
          <div />

          <FlexContainer $padding="40px 0">
            <GlassClockIcon />
          </FlexContainer>

          <Typography.H4 $padding="0 0 40px">Deactivated</Typography.H4>

          <Description>
            Your account has been deactivated. Please contact the administrator
            for more information at <br />
            <Link href="mailto:info@togetheronward.com">
              info@togetheronward.com
            </Link>
          </Description>
        </>
      )}
    </Wrapper>
  );
};
