// Lib
import { FC } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
import { Timer } from "components";
// Styled
import { FlexContainer } from "styled/Box";
import { Description, Title } from "./styled";
import { Button } from "styled/Buttons";

interface IncomingRequestProps {
  created_at: string;
  handlePreview: () => void;
  onTimeIsOut: () => void;
}

export const IncomingRequest: FC<IncomingRequestProps> = ({
  created_at,
  handlePreview,
  onTimeIsOut,
}) => {
  return (
    <FlexContainer $fullwidth $justify="center">
      <FlexContainer
        $fullwidth
        $column
        $width="400px"
        $align="center"
        $justify="center"
        $padding="80px 20px"
      >
        <FlexContainer $fullwidth $column $padding="0 0 40px">
          <Title $padding="0 0 20px">
            We have a counseling request for you.
          </Title>

          <Description>
            Please start the session within the next 15 minutes.
          </Description>
        </FlexContainer>

        <Timer
          type="heading"
          startDate={created_at}
          onTimeIsOut={() => onTimeIsOut()}
        />

        <FlexContainer $fullwidth $column $padding="40px 0">
          <Button.Base onClick={handlePreview}>Review the request</Button.Base>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
