import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const GlassInfoIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="105"
    height="90"
    viewBox="0 0 105 90"
    fill="none"
    {...props}
  >
    <path
      d="M72 1.16553C54.336 1.16553 40 15.5047 40 33.1655C40 50.8327 54.336 65.1655 72 65.1655C89.696 65.1655 104 50.8327 104 33.1655C104 15.5047 89.696 1.16553 72 1.16553Z"
      fill="#4096FE"
    />
    <g opacity="0.5" filter="url(#filter0_f_2884_12561)">
      <path
        d="M72 18.1792C63.7275 18.1792 57.0137 24.8946 57.0137 33.1655C57.0137 41.4395 63.7275 48.1518 72 48.1518C80.2874 48.1518 86.9863 41.4395 86.9863 33.1655C86.9863 24.8946 80.2874 18.1792 72 18.1792Z"
        fill="#0062FF"
      />
    </g>
    <g filter="url(#filter1_b_2884_12561)">
      <mask
        id="path-3-outside-1_2884_12561"
        maskUnits="userSpaceOnUse"
        x="0"
        y="6.16748"
        width="84"
        height="84"
        fill="black"
      >
        <rect fill="white" y="6.16748" width="84" height="84" />
        <path d="M41.8333 7.16748C19.2933 7.16748 1 25.4649 1 48.0008C1 70.5449 19.2933 88.8341 41.8333 88.8341C64.4142 88.8341 82.6667 70.5449 82.6667 48.0008C82.6667 25.4649 64.4142 7.16748 41.8333 7.16748Z" />
      </mask>
      <path
        d="M41.8333 7.16748C19.2933 7.16748 1 25.4649 1 48.0008C1 70.5449 19.2933 88.8341 41.8333 88.8341C64.4142 88.8341 82.6667 70.5449 82.6667 48.0008C82.6667 25.4649 64.4142 7.16748 41.8333 7.16748Z"
        fill="#3EA0FE"
        fillOpacity="0.35"
      />
      <path
        d="M41.8333 6.16748C41.2811 6.16748 40.8333 6.6152 40.8333 7.16748C40.8333 7.71977 41.2811 8.16748 41.8333 8.16748V6.16748ZM41.8333 6.16748C18.741 6.16748 0 24.9127 0 48.0008H2C2 26.0171 19.8457 8.16748 41.8333 8.16748V6.16748ZM0 48.0008C0 71.0972 18.7411 89.8341 41.8333 89.8341V87.8341C19.8456 87.8341 2 69.9925 2 48.0008H0ZM41.8333 89.8341C64.967 89.8341 83.6667 71.0966 83.6667 48.0008H81.6667C81.6667 69.9932 63.8613 87.8341 41.8333 87.8341V89.8341ZM83.6667 48.0008C83.6667 24.9133 64.9672 6.16748 41.8333 6.16748V8.16748C63.8612 8.16748 81.6667 26.0165 81.6667 48.0008H83.6667Z"
        fill="url(#paint0_linear_2884_12561)"
        mask="url(#path-3-outside-1_2884_12561)"
      />
    </g>
    <g filter="url(#filter2_bd_2884_12561)">
      <mask id="path-5-inside-2_2884_12561" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.8336 28.9321C39.8736 28.9321 38.2402 30.5695 38.2402 32.5255V50.5738C38.2402 52.5379 39.8736 54.1263 41.8336 54.1263C43.7936 54.1263 45.3861 52.5379 45.3861 50.5738V32.5255C45.3861 30.5695 43.7936 28.9321 41.8336 28.9321ZM38.2803 63.5208C38.2803 65.4808 39.8728 67.1141 41.8736 67.1141C43.8336 67.1141 45.4261 65.4808 45.4261 63.5208C45.4261 61.5608 43.8336 59.9683 41.8328 59.9683C39.8728 59.9683 38.2803 61.5608 38.2803 63.5208Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.8336 28.9321C39.8736 28.9321 38.2402 30.5695 38.2402 32.5255V50.5738C38.2402 52.5379 39.8736 54.1263 41.8336 54.1263C43.7936 54.1263 45.3861 52.5379 45.3861 50.5738V32.5255C45.3861 30.5695 43.7936 28.9321 41.8336 28.9321ZM38.2803 63.5208C38.2803 65.4808 39.8728 67.1141 41.8736 67.1141C43.8336 67.1141 45.4261 65.4808 45.4261 63.5208C45.4261 61.5608 43.8336 59.9683 41.8328 59.9683C39.8728 59.9683 38.2803 61.5608 38.2803 63.5208Z"
        fill="url(#paint1_linear_2884_12561)"
      />
      <path
        d="M38.6402 32.5255C38.6402 30.7902 40.0948 29.3321 41.8336 29.3321V28.5321C39.6524 28.5321 37.8402 30.3489 37.8402 32.5255H38.6402ZM38.6402 50.5738V32.5255H37.8402V50.5738H38.6402ZM41.8336 53.7263C40.0882 53.7263 38.6402 52.3108 38.6402 50.5738H37.8402C37.8402 52.765 39.6589 54.5263 41.8336 54.5263V53.7263ZM44.9861 50.5738C44.9861 52.3167 43.5729 53.7263 41.8336 53.7263V54.5263C44.0142 54.5263 45.7861 52.7591 45.7861 50.5738H44.9861ZM44.9861 32.5255V50.5738H45.7861V32.5255H44.9861ZM41.8336 29.3321C43.5664 29.3321 44.9861 30.7842 44.9861 32.5255H45.7861C45.7861 30.3549 44.0207 28.5321 41.8336 28.5321V29.3321ZM41.8736 66.7141C40.0966 66.7141 38.6803 65.2628 38.6803 63.5208H37.8803C37.8803 65.6987 39.6489 67.5141 41.8736 67.5141V66.7141ZM45.0261 63.5208C45.0261 65.2658 43.6068 66.7141 41.8736 66.7141V67.5141C44.0604 67.5141 45.8261 65.6957 45.8261 63.5208H45.0261ZM41.8328 60.3683C43.6157 60.3683 45.0261 61.7847 45.0261 63.5208H45.8261C45.8261 61.3369 44.0515 59.5683 41.8328 59.5683V60.3683ZM38.6803 63.5208C38.6803 61.7817 40.0937 60.3683 41.8328 60.3683V59.5683C39.6519 59.5683 37.8803 61.3399 37.8803 63.5208H38.6803Z"
        fill="url(#paint2_linear_2884_12561)"
        mask="url(#path-5-inside-2_2884_12561)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2884_12561"
        x="39.0137"
        y="0.179199"
        width="65.9727"
        height="65.9727"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="9"
          result="effect1_foregroundBlur_2884_12561"
        />
      </filter>
      <filter
        id="filter1_b_2884_12561"
        x="-24"
        y="-17.8325"
        width="131.666"
        height="131.667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2884_12561"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2884_12561"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_bd_2884_12561"
        x="23.2402"
        y="13.9321"
        width="37.1855"
        height="68.1821"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2884_12561"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.25098 0 0 0 0 0.588235 0 0 0 0 0.996078 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_2884_12561"
          result="effect2_dropShadow_2884_12561"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2884_12561"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2884_12561"
        x1="14"
        y1="16.6818"
        x2="66.0946"
        y2="81.2022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2884_12561"
        x1="41.834"
        y1="31.0859"
        x2="35.2208"
        y2="30.5637"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.75" />
        <stop offset="1" stopColor="white" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2884_12561"
        x1="39.3841"
        y1="33.3804"
        x2="50.4007"
        y2="35.9483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
