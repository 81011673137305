import styled from "styled-components";
import { Button as AntButton } from "antd";
import { theme } from "theme";

interface ButtonBase {
  $margin?: string;
  iconEnd?: boolean;
  $errors?: boolean;
}

const Base = styled(AntButton)<ButtonBase>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  font-weight: ${theme.fontWeight.medium};
  padding: 14px 16px;
  height: 52px;
  border-radius: 28px;
  border: none;

  box-shadow: none;
  background: ${({ $errors }) =>
    $errors ? "rgba(104, 104, 130, 0.60)" : "#41c4de"};
  color: ${({ $errors }) =>
    $errors ? theme.color.label.tertiary : "rgba(255, 255, 255, 1)"};

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  ${({ iconEnd }) =>
    iconEnd &&
    `
  flex-direction: row-reverse;
  
  .ant-btn-icon {
    margin-inline-start: 4px;
    margin-inline-end: 0px !important;
  };`}

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: rgba(255, 255, 255, 1);
    background: #2e8ea1;
  }

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: rgba(255, 255, 255, 1);
    background: #41c4de;
  }

  ${({ $margin }) => $margin && `margin: ${$margin}`}
`;

const IconTransparent = styled.button<{
  $withoutHover?: boolean;
  $margin?: string;
}>`
  background: transparent;
  border: none;
  cursor: pointer;

  ${({ $margin }) => $margin && `margin: ${$margin}`}

  ${({ $withoutHover }) =>
    !$withoutHover &&
    `&:hover {
    svg {
      path {
        fill: white;
      }
    }
  }`}
`;

const IconRounded = styled(IconTransparent)<{ $margin?: string }>`
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $margin }) => $margin && `margin: ${$margin}`}
`;

const Transparent = styled(Base)`
  width: auto;

  color: #41c4de;
  background: transparent;

  /* ${({ $margin }) => $margin && `margin: ${$margin}`} */

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #2e8ea1;
    background: transparent;
  }

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: #41c4de;
    background: transparent;
  }
`;
const TransparentBordered = styled(Base)<{ $margin?: string }>`
  width: auto;

  color: #41c4de;
  background: transparent;
  border: 1px solid #41c4de;
  ${({ $margin }) => $margin && `margin: ${$margin}`}

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #2e8ea1;
    background: transparent;
    border-color: #2e8ea1;
  }

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: #41c4de;
    background: transparent;
    border-color: #41c4de;
  }
`;

const Join = styled(IconRounded)<{ $margin?: string }>`
  width: 200px;
  height: 200px;
  background: #41c4de;

  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;

  ${({ $margin }) => $margin && `margin: ${$margin}`}
`;

export const SessionControl = styled(IconRounded)<{
  $isDisconnect?: boolean;
  $withoutHover?: boolean;
  $isMuted?: boolean;
  $margin?: string;
}>`
  height: 68px;
  width: 68px;
  border-radius: 28px;
  margin: 0 8px;
  ${({ $margin }) => $margin && `margin: ${$margin}`}

  background: ${({ $isDisconnect, $isMuted }) =>
    $isDisconnect
      ? "#D15E75"
      : $isMuted
      ? "white"
      : "rgba(255, 255, 255, 0.2)"};

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background: ${({ $isDisconnect }) =>
      $isDisconnect ? "#9a3348" : "rgba(255, 255, 255, 0.1)"};
  }

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    background: ${({ $isDisconnect }) =>
      $isDisconnect ? "#9a3348" : "rgba(255, 255, 255, 0.1)"};
  }
`;

export const Button = {
  Base,
  Transparent,
  IconTransparent,
  IconRounded,
  Join,
  SessionControl,
  TransparentBordered,
};

export type ButtonType = typeof Button.Base;
