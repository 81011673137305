import {
  DoctorInformationData,
  EmergencyContactData,
  ProfileQuestioneer,
  RetrieveConsultationRequest,
} from "types/consultation";

const handleArray = (data: any): string[] | null => {
  return Array.isArray(data) && data.every(item => typeof item === "string")
    ? data
    : null;
};

const handleNumber = (data: any): number | null => {
  return typeof data === "number" ? data : null;
};

const handleString = (data: any): string | null => {
  if (data?.ethnicity) {
    if (data?.["Would you please describe?"]) {
      if (typeof data?.["Would you please describe?"] === "string")
        return `${data?.ethnicity}, ${data?.["Would you please describe?"]}`;
    }

    return typeof data.ethnicity === "string" ? data.ethnicity : null;
  }

  if (data?.YES_OR_NO) {
    if (data?.["Would you please describe?"]) {
      if (typeof data?.["Would you please describe?"] === "string")
        return `${data?.YES_OR_NO}, ${data?.["Would you please describe?"]}`;
    }

    return typeof data.YES_OR_NO === "string" ? data.YES_OR_NO : null;
  }

  return typeof data === "string" ? data : null;
};

const handleStringFromKey = (data: any, key: string): string | null => {
  if (data[key]) {
    if (typeof data[key] === "string") {
      return data[key];
    }
    return null;
  }

  return null;
};

const handleEmergencyContact = (data): EmergencyContactData => {
  const obj = {
    legalName: data?.["Legal Name"] || null,
    phoneNumber: data?.["Phone number"] || null,
    relationship: data?.Relationship || null,
  };

  return obj;
};

const handleDoctorInformation = (data): DoctorInformationData => {
  return {
    name: data?.Name || null,
    phoneNumber: data?.["Contact information (phone number)"] || null,
    address: data?.["Office address"] || null,
  };
};

export const createProfileQuestions = (
  data: RetrieveConsultationRequest,
): ProfileQuestioneer => {
  const answers = {
    time: data?.duration_price?.duration || null,
    type: data?.type || null,
    profile: {
      name: data?.client?.nickname || null,
      ethnicity: null,
      gender: null,
      language: null,
      industries: null,
    },
    emergencyContact: null,
    mentalHealthBackground: {
      diagnosedBefore: null,
      lastTime: null,
      medications: null,
      hospitalizedBefore: null,
      treatmentDuration: null,
      fullyRecoveredSince: null,
      currentlyTakingAnyMedication: null,
      haveSeenCounselorBefore: null,
    },
    doctorInformation: {
      name: null,
      phoneNumber: null,
      address: null,
    },
    additionalInformation: null,
    screeningQuestions: {
      feelingsToday: null,
      feelingsScale: null,
      feelingsComeFrom: null,
      sleepingPatterns: null,
      panicAttack: null,
      counselorLastVisit: null,
      medication: null,
      suicideExpirience: null,
      counselorGender: null,
      counselorLanguage: null,
      counselorEthnicity: null,
    },
  };

  data?.answers?.forEach(i => {
    switch (i?.question?.id) {
      case 9:
        answers.screeningQuestions.feelingsToday = handleArray(i?.text);
        break;
      case 10:
        answers.screeningQuestions.feelingsScale = handleNumber(i?.text);
        break;
      case 11:
        answers.screeningQuestions.feelingsComeFrom = handleArray(i?.text);
        break;
      case 16:
        answers.screeningQuestions.suicideExpirience = handleString(i?.text);
        break;
      case 12:
        answers.screeningQuestions.sleepingPatterns = handleString(i?.text);
        break;
      case 13:
        answers.screeningQuestions.panicAttack = handleString(i?.text);
        break;
      case 14:
        answers.screeningQuestions.counselorLastVisit = handleString(i?.text);
        break;
      case 15:
        answers.screeningQuestions.medication = handleString(i?.text);
        break;
      case 17:
        answers.screeningQuestions.counselorGender = handleString(i?.text);
        break;
      case 19:
        answers.screeningQuestions.counselorEthnicity = handleString(i?.text);
        break;
      case 18:
        answers.screeningQuestions.counselorLanguage = handleString(i?.text);
        break;
      default:
        break;
    }
  });

  data?.sign_up_answers?.forEach(i => {
    switch (i?.question?.id) {
      case 20:
        answers.profile.gender = handleString(i?.text);
        break;
      case 1:
        answers.profile.ethnicity = handleString(i?.text);
        break;
      case 2:
        answers.profile.language = handleArray(i?.text);
        break;
      case 3:
        answers.profile.industries = handleArray(i?.text);
        break;
      case 8:
        answers.additionalInformation = handleArray(i?.text);
        break;
      case 4:
        answers.emergencyContact = handleEmergencyContact(i?.text);
        break;
      case 7:
        answers.doctorInformation = handleDoctorInformation(i?.text);
        break;
      case 21:
        answers.mentalHealthBackground.haveSeenCounselorBefore = handleString(
          i?.text,
        );
        break;
      case 5:
        answers.mentalHealthBackground.diagnosedBefore = handleStringFromKey(
          i?.text,
          "YES_OR_NO",
        );
        answers.mentalHealthBackground.lastTime = handleStringFromKey(
          i?.text,
          "When was the last time you had?",
        );
        answers.mentalHealthBackground.medications = handleStringFromKey(
          i?.text,
          "Did you take any medication?",
        );
        answers.mentalHealthBackground.hospitalizedBefore = handleStringFromKey(
          i?.text,
          "Were you hospitalized before?",
        );
        answers.mentalHealthBackground.treatmentDuration = handleStringFromKey(
          i?.text,
          "What was the duration of the treatment?",
        );
        answers.mentalHealthBackground.fullyRecoveredSince =
          handleStringFromKey(i.text, "Have you fully recovered since then?");

        answers.mentalHealthBackground.currentlyTakingAnyMedication =
          handleStringFromKey(
            i?.text,
            "Are you currently taking any medication or receiving a long-term treatment plan?",
          );
        break;

      default:
        break;
    }
  });

  return answers;
};
