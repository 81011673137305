import { LgbtqIcon, MaleIcon, FemaleIcon } from "icons";

export const getGenderIcon = (gender: string | null): JSX.Element => {
  if (!gender) return null;

  switch (gender.toLowerCase()) {
    case "lgbtq+":
      return <LgbtqIcon />;
    case "male":
      return <MaleIcon />;
    case "female":
      return <FemaleIcon />;

    default:
      return null;
  }
};
