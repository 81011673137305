import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const AudioIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.7471 6.07991C12.4871 6.07991 12.2804 6.28658 12.2804 6.54658V7.59991C12.2804 9.95991 10.3604 11.8799 8.00044 11.8799C5.64044 11.8799 3.72044 9.95991 3.72044 7.59991V6.53991C3.72044 6.27991 3.51378 6.07324 3.25378 6.07324C2.99378 6.07324 2.78711 6.27991 2.78711 6.53991V7.59324C2.78711 10.3066 4.87378 12.5399 7.53378 12.7799V14.1999C7.53378 14.4599 7.74044 14.6666 8.00044 14.6666C8.26044 14.6666 8.46711 14.4599 8.46711 14.1999V12.7799C11.1204 12.5466 13.2138 10.3066 13.2138 7.59324V6.53991C13.2071 6.28658 13.0004 6.07991 12.7471 6.07991Z"
      fill="white"
    />
    <path
      d="M8.00038 1.3335C6.37371 1.3335 5.05371 2.6535 5.05371 4.28016V7.6935C5.05371 9.32016 6.37371 10.6402 8.00038 10.6402C9.62704 10.6402 10.947 9.32016 10.947 7.6935V4.28016C10.947 2.6535 9.62704 1.3335 8.00038 1.3335ZM8.87371 5.96683C8.82704 6.14016 8.67371 6.2535 8.50038 6.2535C8.46704 6.2535 8.43371 6.24683 8.40038 6.24016C8.14038 6.16683 7.86704 6.16683 7.60704 6.24016C7.39371 6.30016 7.18704 6.1735 7.13371 5.96683C7.07371 5.76016 7.20038 5.54683 7.40704 5.4935C7.80038 5.38683 8.21371 5.38683 8.60704 5.4935C8.80704 5.54683 8.92704 5.76016 8.87371 5.96683ZM9.22704 4.6735C9.16704 4.8335 9.02038 4.92683 8.86038 4.92683C8.81371 4.92683 8.77371 4.92016 8.72704 4.90683C8.26038 4.7335 7.74038 4.7335 7.27371 4.90683C7.07371 4.98016 6.84704 4.8735 6.77371 4.6735C6.70038 4.4735 6.80704 4.24683 7.00704 4.18016C7.64704 3.94683 8.35371 3.94683 8.99371 4.18016C9.19371 4.2535 9.30038 4.4735 9.22704 4.6735Z"
      fill="white"
    />
  </svg>
);
