import { styled } from "styled-components";
import { Typography } from "styled/Typography";

export const Title = styled(Typography.H5)`
  padding-top: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 100%;
`;
