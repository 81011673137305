import useVideoContext from "twillio/hooks/useVideoContext/useVideoContext";
import useLocalAudioToggle from "twillio/hooks/useLocalAudioToggle/useLocalAudioToggle";
import { Button } from "styled/Buttons";
import { MicIcon, MicOffIcon } from "icons";
import { Popover } from "antd";

export default function ToggleAudioButton(props: { disabled?: boolean }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === "audio");

  return (
    <Popover
      content={!hasAudioTrack ? "No Audio" : isAudioEnabled ? "Mute" : "Unmute"}
    >
      <Button.SessionControl
        $withoutHover
        $isMuted={!isAudioEnabled}
        onClick={toggleAudioEnabled}
        disabled={!hasAudioTrack || props.disabled}
        data-cy-audio-toggle
      >
        {isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      </Button.SessionControl>
    </Popover>
  );
}
