// Lib
import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import {
  useDeleteAccountMutation,
  useLazyLoginQuery,
} from "rtk/query/loginAPI";
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
import { token } from "utils/handleToken";
// Icons
import { EmailIcon, LockIcon, LogoIcon, WarningIcon } from "icons";
// Layouts
// Components
import { Input, InputPassword } from "components";
// Styled
import { FlexContainer, HorizontalDevider } from "styled/Box";
import { Button } from "styled/Buttons";
import { ErrorContainer, Wrapper } from "./styled";

import { resolver } from "./validation";
import { useNotification } from "hooks";

interface LoginFormTypes {
  email?: string;
  password?: string;
}

export const DeleteAccount: FC = () => {
  const { openNotification } = useNotification();

  const [login] = useLazyLoginQuery();
  const [remove] = useDeleteAccountMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<LoginFormTypes>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver,
  });

  useEffect(() => {
    if (errors) {
      const message = Object.keys(errors)
        .map(key => errors[key]?.message || "")
        .join("; ");
      setErrorMessage(message);
    }
  }, [errors]);

  const onSubmit: SubmitHandler<LoginFormTypes> = async ({
    email,
    password,
  }) => {
    setIsLoading(true);

    try {
      const data = await login({ email, password }).unwrap();

      const { access_token } = data || {};

      if (access_token) {
        token.access.set(access_token, "localStorage");

        await remove().unwrap();

        openNotification({ message: "Your account has been deleted" });

        token.clear();
      } else {
        setErrorMessage("Account didn`t found");
      }

      reset({
        email: "",
        password: "",
      });
    } catch (error) {
      if (error?.status === 400) {
        setErrorMessage(
          "Invalid email or password. Please check and try again",
        );
      } else {
        setErrorMessage("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <FlexContainer $padding="0 0 40px">
        <LogoIcon />
      </FlexContainer>

      <>
        <HorizontalDevider $width="123px" />

        <FlexContainer $fullwidth $column $padding="40px 0">
          <FlexContainer $fullwidth $padding="0 0 8px 0">
            <Controller
              name={"email"}
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  prefix={<EmailIcon />}
                  placeholder="Email"
                  value={"sdsds"}
                  disabled={isLoading}
                  {...field}
                  fieldState={fieldState}
                />
              )}
            />
          </FlexContainer>

          <Controller
            name={"password"}
            control={control}
            render={({ field, fieldState }) => (
              <InputPassword
                prefix={<LockIcon />}
                disabled={isLoading}
                {...field}
                fieldState={fieldState}
              />
            )}
          />
        </FlexContainer>

        <Button.Base htmlType="submit" loading={isLoading}>
          Delete Account
        </Button.Base>

        {errorMessage && (
          <ErrorContainer>
            <WarningIcon />
            <span>{errorMessage}</span>
          </ErrorContainer>
        )}
      </>
    </Wrapper>
  );
};
