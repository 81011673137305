// Lib
import { FC } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled
import { Answer, Container, QuestionTitle } from "./styled";
import { FlexContainer } from "styled/Box";
import { DoctorInformationData } from "types/consultation";

interface DoctorInformationProps {
  data: DoctorInformationData;
}

export const DoctorInformation: FC<DoctorInformationProps> = ({ data }) => {
  return (
    <Container>
      <QuestionTitle $padding="0 0 16px">
        Please provide your primary doctor information
      </QuestionTitle>

      <FlexContainer $fullwidth $column $gap={8}>
        {data?.name && <Answer>{data?.name}</Answer>}

        {data?.phoneNumber && <Answer>{data?.phoneNumber}</Answer>}

        {data?.address && <Answer>{data?.address}</Answer>}
      </FlexContainer>
    </Container>
  );
};
