// Lib
import { FC } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled
import { Wrapper } from "./styled";

interface BadgeProps {
  title: string;
}

export const Badge: FC<BadgeProps> = ({ title }) => {
  return <Wrapper>{title}</Wrapper>;
};
