// Lib
import { FC } from "react";
import { useNavigate } from "react-router-dom";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
import { GlassDangerIcon } from "icons";
// Layouts
// Components
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Description, ExpiredTitle } from "./styled";

export const ExpiredLink: FC = () => {
  const navigate = useNavigate();

  return (
    <FlexContainer $fullwidth $justify="center">
      <FlexContainer
        $fullwidth
        $column
        $width="361px"
        $align="center"
        $justify="center"
        $padding="80px 20px"
      >
        <GlassDangerIcon />

        <ExpiredTitle> Link expired</ExpiredTitle>

        <Description>
          Sorry, we noticed you weren&apos;t able to join the counseling
          session.
        </Description>

        <Button.Base onClick={() => navigate("/home")}>Got it</Button.Base>
      </FlexContainer>
    </FlexContainer>
  );
};
