import { Modal } from "components";
import { EndCallIcon } from "icons";
import { useCallback, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useLazyGetCurrentConsultationCheckQuery,
  useLazyFinishConsultationQuery,
} from "rtk/query/consultationAPI";
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import useLocalVideoToggle from "twillio/hooks/useLocalVideoToggle/useLocalVideoToggle";
import useVideoContext from "twillio/hooks/useVideoContext/useVideoContext";

export default function EndCallButton() {
  const navigate = useNavigate();

  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { id, name, token } = useParams();

  const [check, { isFetching: isCheckLoading }] =
    useLazyGetCurrentConsultationCheckQuery();
  const [finish, { isFetching: isFinishLoading }] =
    useLazyFinishConsultationQuery();

  const { room, removeLocalAudioTrack, removeLocalVideoTrack } =
    useVideoContext();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  const [isExitModal, setExitModal] = useState(false);

  const handleNo = async () => {
    const { status } = await check().unwrap();
    if (isVideoEnabled) {
      toggleVideo();
    }
    await removeLocalAudioTrack();
    await removeLocalVideoTrack();
    await room!.disconnect();

    if (status === "accepted") {
      navigate(`/consultation/${id}?step=profile`);
      return;
    }

    if (status === "started") {
      navigate(`/join/${id}/${name}/${token}`);
      return;
    }
    navigate(`/home/`);
  };

  const handleFinish = async () => {
    try {
      await finish().unwrap();
      window.sessionStorage.removeItem("status");

      if (isVideoEnabled) {
        toggleVideo();
      }

      await removeLocalAudioTrack();
      await removeLocalVideoTrack();
      await room!.disconnect();

      navigate(`/congrats/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = async () => {
    try {
      const { status } = await check().unwrap();

      if (status == "accepted") {
        if (isVideoEnabled) {
          toggleVideo();
        }

        await removeLocalAudioTrack();
        await removeLocalVideoTrack();
        await room!.disconnect();
        navigate(`/consultation/${id}?step=profile`);
        return;
      }

      setExitModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setExitModal(false);
  };

  return (
    <>
      <Button.SessionControl
        $isDisconnect
        data-cy-disconnect
        onClick={handleClick}
      >
        <EndCallIcon />
      </Button.SessionControl>

      <Modal
        open={isExitModal}
        onClose={handleClose}
        isLoading={isFinishLoading || isCheckLoading}
      >
        <FlexContainer $fullwidth $column>
          <FlexContainer $fullwidth $justify="center" $padding="30px 0 50px 0">
            <Typography.Body1Semibold>
              Finish this consultation?
            </Typography.Body1Semibold>
          </FlexContainer>

          <FlexContainer $fullwidth $column>
            <Button.TransparentBordered
              style={{ marginBottom: "25px" }}
              loading={isCheckLoading}
              disabled={isFinishLoading}
              onClick={handleNo}
            >
              No
            </Button.TransparentBordered>

            <Button.Base
              loading={isFinishLoading}
              disabled={isCheckLoading}
              onClick={handleFinish}
            >
              Finish
            </Button.Base>
          </FlexContainer>
        </FlexContainer>
      </Modal>
    </>
  );
}
