// Lib
import { FC } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
import { ScreeningQuestionsData } from "types/consultation";
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
import { AdditionalInformation } from "../AdditionalInformation";
import { Question } from "../Question";
// Styled
import { Title } from "./styled";

interface ScreeningQuestionsProps {
  data: ScreeningQuestionsData;
}

export const ScreeningQuestions: FC<ScreeningQuestionsProps> = ({ data }) => {
  const isAnswers = Object.keys(data)?.length;

  if (!isAnswers) {
    return null;
  }

  return (
    <>
      <Title $padding="0 0 8px 0">Request & Screening Questions</Title>

      {!!data?.feelingsToday && (
        <AdditionalInformation
          title={"How can we assist you today?"}
          data={data?.feelingsToday}
        />
      )}

      {!!data?.feelingsScale?.toString().length && (
        <Question
          question="On a scale of 1 to 5, how intense is your feeling?"
          answer={data.feelingsScale.toString()}
        />
      )}

      {!!data?.feelingsComeFrom?.length && (
        <AdditionalInformation
          title={"Where does that feeling come from?"}
          data={data.feelingsComeFrom}
        />
      )}

      {data?.sleepingPatterns && (
        <Question
          question="How would you characterize your current sleeping patterns or habits?"
          answer={data.sleepingPatterns}
        />
      )}
      {data?.panicAttack && (
        <Question
          question="Are you currently experiencing a panic attack?"
          answer={data.panicAttack}
        />
      )}

      {data?.counselorLastVisit && (
        <Question
          question="When did you last visit a mental health counselor or psychiatrist?"
          answer={data.counselorLastVisit}
        />
      )}

      {data?.medication && (
        <Question
          question="Are you currently taking any medication or undergoing any form of treatment?"
          answer={data.medication}
        />
      )}

      {data?.suicideExpirience && (
        <Question
          question="Have you been experiencing any thoughts of self-harm or suicide, or harming others?"
          answer={data.suicideExpirience}
        />
      )}

      {data?.counselorGender && (
        <Question
          question="Do you have a preference for the gender of your counselor?"
          answer={data.counselorGender}
        />
      )}

      {data?.counselorLanguage && (
        <Question
          question="Do you have a preferred language for your counselor?"
          answer={data.counselorLanguage}
        />
      )}

      {data?.counselorEthnicity && (
        <Question
          question="Do you have any ethnicity preference for your counselor?"
          answer={data.counselorEthnicity}
        />
      )}
    </>
  );
};
