// Lib
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// Api
import { loginAPI } from "rtk/query/loginAPI";
import { consultationAPI } from "rtk/query/consultationAPI";
// Selectors
import { authSliceReducer } from "./rtk/slices";
// Types
import { AuthState } from "types/auth";
// Utils
import { token } from "utils/handleToken";

const LOGOUT_ACTIONS = ["auth/userLogout"];

const reducers = combineReducers({
  auth: authSliceReducer,
  [loginAPI.reducerPath]: loginAPI.reducer,
  [consultationAPI.reducerPath]: consultationAPI.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = (state, action) => {
  if (LOGOUT_ACTIONS.includes(action.type)) {
    state = undefined;
    token.clear();
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(loginAPI.middleware, consultationAPI.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export type RootState = {
  auth: AuthState;
};
