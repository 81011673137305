// Lib
import { FC } from "react";
// Api
// Hooks
import { useCountdownTimer } from "hooks";
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled
import { TimeBadge, TimeHeading, WrapperHeading } from "./styled";

interface TimerProps {
  type: "heading" | "badge";
  startDate: string | undefined;
  onTimeIsOut?: () => void;
}

export const Timer: FC<TimerProps> = ({ type, startDate, onTimeIsOut }) => {
  const { time, isTimeOver } = useCountdownTimer(startDate);

  if (!!onTimeIsOut && time === "00:00") {
    onTimeIsOut();
  }

  if (type === "heading") {
    return (
      <WrapperHeading>
        <TimeHeading>{time}</TimeHeading>
      </WrapperHeading>
    );
  }

  if (type === "badge") {
    return time ? (
      <TimeBadge $isTimeOver={isTimeOver}>{time}</TimeBadge>
    ) : (
      <div style={{ width: "53px" }} />
    );
  }

  return null;
};
