import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
`;

export const RoomWrapper = styled.div`
  flex-grow: 1;
`;

export const Padding = styled.div`
  height: 7vh;

  @media (max-width: 1200px) {
    height: 0px;
  }
`;
