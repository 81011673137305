import { styled } from "styled-components";
import { Typography } from "styled/Typography";

export const Container = styled.div`
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const QuestionTitle = styled(Typography.Body2Regular)`
  color: rgba(235, 235, 245, 0.8);
`;

export const Answer = styled(Typography.Body2Semibold)``;
