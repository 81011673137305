// import Menu from "./Menu/Menu";

import useRoomState from "twillio/hooks/useRoomState/useRoomState";
import ToggleAudioButton from "twillio/components/ToggleAudioButton/ToggleAudioButton";
// import ToggleVideoButton from "twillio/components/ToggleVideoButton/ToggleVideoButton";
// import useVideoContext from "twillio/hooks/useVideoContext/useVideoContext";
import EndCallButton from "twillio/components/EndCallButton/EndCallButton";
import { Footer } from "./styled";

export default function MenuBar() {
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";
  // const { room } = useVideoContext();
  // const isVideo = window.sessionStorage.getItem("type") === "video";

  return (
    <>
      <Footer>
        <ToggleAudioButton disabled={isReconnecting} />
        <EndCallButton />
        {/* {isVideo ? <ToggleVideoButton disabled={isReconnecting} /> : <div />} */}
        <div>{/* <Menu /> */}</div>
      </Footer>
    </>
  );
}
