import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const GlassDiscoveryIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="107"
    height="107"
    viewBox="0 0 107 107"
    fill="none"
    {...props}
  >
    <g filter="url(#filter0_b_2855_750)">
      <path
        d="M89.3332 48.8333C89.3332 71.3856 71.0521 89.6667 48.4998 89.6667C25.9476 89.6667 7.6665 71.3856 7.6665 48.8333C7.6665 26.2852 25.9476 8 48.4998 8C71.0521 8 89.3332 26.2852 89.3332 48.8333Z"
        fill="url(#paint0_linear_2855_750)"
      />
    </g>
    <g filter="url(#filter1_f_2855_750)">
      <path
        d="M48.6056 22.2559C34.1056 22.2559 22.3374 34.0266 22.3374 48.5241C22.3374 63.0267 34.1056 74.7922 48.6056 74.7922C63.1319 74.7922 74.8738 63.0267 74.8738 48.5241C74.8738 34.0266 63.1319 22.2559 48.6056 22.2559Z"
        fill="url(#paint1_linear_2855_750)"
      />
    </g>
    <g filter="url(#filter2_b_2855_750)">
      <path
        d="M105.333 64.8333C105.333 87.3856 87.0521 105.667 64.4998 105.667C41.9476 105.667 23.6665 87.3856 23.6665 64.8333C23.6665 42.2852 41.9476 24 64.4998 24C87.0521 24 105.333 42.2852 105.333 64.8333Z"
        fill="#865CD5"
      />
      <path
        d="M64.4998 106.167C87.3282 106.167 105.833 87.6617 105.833 64.8333C105.833 42.0091 87.3283 23.5 64.4998 23.5C41.6714 23.5 23.1665 42.0091 23.1665 64.8333C23.1665 87.6617 41.6714 106.167 64.4998 106.167Z"
        stroke="url(#paint2_linear_2855_750)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#filter3_bd_2855_750)">
      <mask id="path-5-inside-1_2855_750" fill="white">
        <path d="M80.2612 50.3783L73.6462 71.285C73.4012 72.1425 72.7071 72.8366 71.8496 73.0857L51.0246 79.615C49.6362 80.0682 48.2887 78.7166 48.7379 77.3283L55.2712 56.3808C55.5162 55.5233 56.2104 54.87 57.0679 54.5841L77.9746 48.0508C79.4037 47.6016 80.7104 48.9491 80.2612 50.3783Z" />
      </mask>
      <path
        d="M80.2612 50.3783L73.6462 71.285C73.4012 72.1425 72.7071 72.8366 71.8496 73.0857L51.0246 79.615C49.6362 80.0682 48.2887 78.7166 48.7379 77.3283L55.2712 56.3808C55.5162 55.5233 56.2104 54.87 57.0679 54.5841L77.9746 48.0508C79.4037 47.6016 80.7104 48.9491 80.2612 50.3783Z"
        fill="url(#paint3_linear_2855_750)"
      />
      <path
        d="M73.6462 71.285L73.2647 71.1643L73.2616 71.1751L73.6462 71.285ZM71.8496 73.0857L71.738 72.7015L71.7299 72.704L71.8496 73.0857ZM51.0246 79.615L50.9049 79.2333L50.9004 79.2347L51.0246 79.615ZM48.7379 77.3283L49.1185 77.4514L49.1198 77.4474L48.7379 77.3283ZM55.2712 56.3808L55.6532 56.4999L55.6559 56.4907L55.2712 56.3808ZM57.0679 54.5841L56.9486 54.2023L56.9414 54.2047L57.0679 54.5841ZM77.9746 48.0508L78.0939 48.4326L78.0945 48.4324L77.9746 48.0508ZM79.8799 50.2576L73.2649 71.1643L74.0276 71.4056L80.6426 50.499L79.8799 50.2576ZM73.2616 71.1751C73.0547 71.8995 72.4642 72.4907 71.738 72.7016L71.9612 73.4698C72.95 73.1826 73.7478 72.3854 74.0308 71.3949L73.2616 71.1751ZM71.7299 72.704L50.9049 79.2333L51.1442 79.9967L71.9692 73.4674L71.7299 72.704ZM50.9004 79.2347C49.8249 79.5858 48.7692 78.5309 49.1185 77.4514L48.3573 77.2052C47.8082 78.9024 49.4476 80.5506 51.1487 79.9952L50.9004 79.2347ZM49.1198 77.4474L55.6531 56.4999L54.8894 56.2617L48.3561 77.2092L49.1198 77.4474ZM55.6559 56.4907C55.8598 55.777 56.4445 55.2136 57.1944 54.9636L56.9414 54.2047C55.9763 54.5264 55.1727 55.2696 54.8866 56.2709L55.6559 56.4907ZM57.1872 54.9659L78.0939 48.4326L77.8553 47.669L56.9486 54.2023L57.1872 54.9659ZM78.0945 48.4324C79.2029 48.084 80.2358 49.1252 79.8796 50.2584L80.6428 50.4982C81.185 48.773 79.6046 47.1192 77.8546 47.6692L78.0945 48.4324Z"
        fill="url(#paint4_linear_2855_750)"
        mask="url(#path-5-inside-1_2855_750)"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_2855_750"
        x="-5.3335"
        y="-5"
        width="107.667"
        height="107.667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2855_750"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2855_750"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_f_2855_750"
        x="0.337402"
        y="0.255859"
        width="96.5366"
        height="96.5366"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="11"
          result="effect1_foregroundBlur_2855_750"
        />
      </filter>
      <filter
        id="filter2_b_2855_750"
        x="7.6665"
        y="8"
        width="113.667"
        height="113.667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2855_750"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2855_750"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_bd_2855_750"
        x="33.6499"
        y="32.9629"
        width="61.7012"
        height="61.7412"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2855_750"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.447059 0 0 0 0 0.368627 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_2855_750"
          result="effect2_dropShadow_2855_750"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2855_750"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2855_750"
        x1="2.41482"
        y1="59.2187"
        x2="96.3551"
        y2="38.684"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0504107" stopColor="#38ABF5" />
        <stop offset="1" stopColor="#C51AB9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2855_750"
        x1="18.959"
        y1="55.205"
        x2="79.391"
        y2="41.995"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0504107" stopColor="#38ABF5" />
        <stop offset="1" stopColor="#C51AB9" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2855_750"
        x1="36.6665"
        y1="33.5143"
        x2="88.7611"
        y2="98.0347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2855_750"
        x1="64.8735"
        y1="53.5"
        x2="59.2353"
        y2="77.8414"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2855_750"
        x1="53.6962"
        y1="51.6608"
        x2="73.9492"
        y2="76.7129"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
