export enum RegistrationStatus {
  RegistrationInProgress = "registration_in_progress",
  RegistrationCompleted = "registration_completed",
  DeactivatedByAdmin = "deactivated_by_admin",
  ActivatedByAdmin = "activated_by_admin",
  ReadyForConsultations = "ready_for_consultations",
}

export interface AuthUserRequest {
  email: string;
  password: string;
}

export interface AuthUserResponse {
  access_token: string;
  is_active: boolean;
  is_code_verified: boolean;
  questionnaire_completed: null;
  registration_process_status: RegistrationStatus;
  role: "counselor";
}

export interface AuthState {
  isAuth: boolean;
}
