export const theme = {
  color: {
    label: {
      primary: "#FFF",
      secondary: "rgba(235, 235, 245, 0.60)",
      tertiary: "rgba(235, 235, 245, 0.30)",
      quaternary: "rgba(235, 235, 245, 0.18)",
    },
    fill: {
      primary: "rgba(120, 120, 128, 0.36)",
      secondary: "rgba(120, 120, 128, 0.32)",
      tertiary: "rgba(118, 118, 128, 0.24)",
      quaternary: "rgba(116, 116, 128, 0.18)",
    },
    separator: {
      opaque: "#38383A",
      non_opaque: "rgba(84, 84, 88, 0.65)",
    },
    grays: {
      gray1: "#8E8E93",
      gray2: "#636366",
      gray3: "#48484A",
      gray4: "#3A3A3C",
      gray5: "#2C2C2E",
      gray6: "#1C1C1E",
    },
    colors: {
      red: "#FF453A",
      orange: "#FF9F0A",
      yellow: "#FFD60A",
      green: "#30D158",
      mint: "#63E6E2",
      teal: "#40CBE0",
      cyan: "#64D2FF",
      blue: "#0A84FF",
      indigo: "#5E5CE6",
      purple: "#BF5AF2",
      pink: "#FF375F",
      brown: "#A2845E",
    },
    tinted: {
      red: "rgba(255, 69, 58, 0.25)",
      orange: "rgba(255, 159, 10, 0.25)",
      yellow: "rgba(255, 214, 10, 0.25)",
      green: "rgba(48, 209, 88, 0.25)",
      mint: "rgba(99, 230, 226, 0.25)",
      teal: "rgba(64, 203, 224, 0.25)",
      cyan: "rgba(100, 210, 255, 0.25)",
      blue: "rgba(10, 132, 255, 0.25)",
      indigo: "rgba(94, 92, 230, 0.25)",
      purple: "rgba(191, 90, 242, 0.25)",
      pink: "rgba(255, 55, 95, 0.25)",
      brown: "rgba(172, 142, 104, 0.25)",
    },
    backgrounds: {
      primary: "#000000",
      secondary: "#1C1C1E",
    },
  },

  borderRadius: {
    base: "16px",
  },

  fontSize: {
    h1: "48px",
    h2: "40px",
    h3: "32px",
    h4: "24px",
    h5: "18px",
    b1: "16px",
    b2: "14px",
    heading: "28px",
    subheading: "20px",
    blockheading: "18px",
  },

  lineHeight: {
    h1: "50px",
    h2: "48px",
    h3: "40px",
    h4: "32px",
    h5: "24px",
    b1: "22px",
    b2: "20px",
    md: "24px",
    subheading: "28px",
    heading: "32px",
  },

  fontWeight: {
    thin: 100,
    extralight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },

  breakpoints: {
    xxl: "1440px",
    xl: "1280px",
    l: "1024px",
    ml: "991px",
    m: "768px",
    s: "512px",
    xs: "480px",
    desktop: "1440px",
    tabletLandscape: "1024px",
  },
};
