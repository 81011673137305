import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const LgbtqIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M50.0625 0.5C51.3828 0.5 52.5 1.61719 52.5 2.9375V11.0625C52.5 12.4844 51.3828 13.5 50.0625 13.5C48.6406 13.5 47.625 12.4844 47.625 11.0625V8.82812L39.5 16.9531C41.5312 19.6953 42.75 22.9453 42.75 26.5C42.75 34.7266 36.6562 41.3281 28.9375 42.5469V44.375L31.375 44.4766C32.6953 44.4766 33.8125 45.4922 33.8125 46.8125C33.8125 48.2344 32.6953 49.25 31.375 49.25H28.9375V50.0625C28.9375 51.4844 27.8203 52.5 26.5 52.5C25.0781 52.5 24.0625 51.4844 24.0625 50.0625V49.25H21.625C20.2031 49.25 19.1875 48.0312 19.1875 46.8125C19.1875 45.4922 20.2031 44.375 21.625 44.375H24.0625V42.5469C16.2422 41.3281 10.25 34.7266 10.25 26.5C10.25 22.9453 11.3672 19.5938 13.3984 16.9531L11.875 15.3281L10.25 16.8516C9.84375 17.3594 9.23438 17.5625 8.625 17.5625C7.91406 17.5625 7.30469 17.3594 6.89844 16.8516C5.88281 15.9375 5.88281 14.4141 6.89844 13.3984L8.42188 11.875L5.375 8.82812V11.0625C5.375 12.4844 4.25781 13.5 2.9375 13.5C1.51562 13.5 0.5 12.4844 0.5 11.0625V2.9375C0.5 1.61719 1.51562 0.5 2.9375 0.5H11.0625C12.3828 0.5 13.5 1.61719 13.5 2.9375C13.5 4.35938 12.3828 5.375 11.0625 5.375H8.72656L11.875 8.52344L13.2969 7C14.3125 5.98438 15.8359 5.98438 16.75 7C17.7656 7.91406 17.7656 9.4375 16.75 10.3516L15.2266 11.875L16.8516 13.5C19.5938 11.4688 22.8438 10.25 26.5 10.25C30.0547 10.25 33.3047 11.4688 36.0469 13.5L44.1719 5.375H41.9375C40.5156 5.375 39.5 4.35938 39.5 2.9375C39.5 1.61719 40.5156 0.5 41.9375 0.5H50.0625ZM26.5 37.875C32.6953 37.875 37.875 32.7969 37.875 26.5C37.875 23.4531 36.5547 20.6094 34.5234 18.5781C34.5234 18.5781 34.5234 18.5781 34.5234 18.4766C34.4219 18.4766 34.4219 18.4766 34.4219 18.4766C32.3906 16.4453 29.5469 15.125 26.5 15.125C23.3516 15.125 20.5078 16.4453 18.4766 18.4766C18.4766 18.4766 18.2734 18.375 18.375 18.4766C18.375 18.5781 18.375 18.5781 18.375 18.5781C16.3438 20.6094 15.125 23.4531 15.125 26.5C15.125 32.7969 20.2031 37.875 26.5 37.875Z"
      fill="#EBEBF5"
      fillOpacity="0.6"
    />
  </svg>
);
