import { Timer } from "components";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetCurrentConsultationQuery,
  useLazyStartConsultationQuery,
} from "rtk/query/consultationAPI";
import useLocalVideoToggle from "twillio/hooks/useLocalVideoToggle/useLocalVideoToggle";
import useParticipants from "twillio/hooks/useParticipants/useParticipants";
import useVideoContext from "twillio/hooks/useVideoContext/useVideoContext";

export const MeetingTimer = () => {
  const navigate = useNavigate();
  const participants = useParticipants();

  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const { room, removeLocalAudioTrack, removeLocalVideoTrack } =
    useVideoContext();

  const [startedAt, setStartedAt] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");

  const [startConsultation] = useLazyStartConsultationQuery();
  const [getConsultationData] = useLazyGetCurrentConsultationQuery();

  const toggleVideo = useCallback(() => {
    toggleVideoEnabled();
  }, [toggleVideoEnabled]);

  const handleMeetingStart = async () => {
    try {
      const initialData = await getConsultationData().unwrap();

      if (initialData?.started_at) {
        setStartedAt(
          dayjs(initialData?.started_at)
            .add(
              initialData?.consultation_request?.duration_price?.duration,
              "minutes",
            )
            .toISOString(),
        );
        window.sessionStorage.setItem("status", "started");
        setCreatedAt("");
      } else {
        if (participants?.length) {
          await startConsultation().unwrap();

          const data = await getConsultationData().unwrap();
          setStartedAt(
            dayjs(data?.started_at)
              .add(
                data?.consultation_request?.duration_price?.duration,
                "minutes",
              )
              .toISOString(),
          );
          setCreatedAt("");
          window.sessionStorage.setItem("status", "started");
        } else {
          const data = await getConsultationData().unwrap();
          setCreatedAt(
            dayjs(data?.created_at).add(15, "minutes").toISOString(),
          );
        }
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    handleMeetingStart();
  }, [participants]);

  const handleDisconnect = async () => {
    window.sessionStorage.removeItem("status");

    if (isVideoEnabled) {
      await toggleVideo();
    }

    await removeLocalAudioTrack();
    await removeLocalVideoTrack();
    await room!.disconnect();

    navigate(`/home/`);
  };

  if (startedAt) {
    return (
      <div
        style={{
          position: "absolute",
          top: "25px",
          right: "30px",
          zIndex: 500,
        }}
      >
        <Timer type={"badge"} startDate={startedAt} />
      </div>
    );
  }

  if (createdAt) {
    return (
      <div
        style={{
          position: "absolute",
          top: "25px",
          right: "30px",
          zIndex: 500,
        }}
      >
        <Timer
          type={"badge"}
          startDate={createdAt}
          onTimeIsOut={handleDisconnect}
        />
      </div>
    );
  }

  return null;
};
