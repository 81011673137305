import { styled } from "styled-components";

export const TimeHeading = styled.p`
  color: white;
  text-align: center;
  font-family: "Poppins";
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 100% */
`;
export const TimeBadge = styled.div<{ $isTimeOver: boolean }>`
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  width: 53px;
  height: 28px;

  ${({ $isTimeOver }) =>
    $isTimeOver &&
    `border: 0.5px solid rgb(255 0 0 / 45%); background: rgb(226 70 70 / 36%);`}
`;

export const WrapperHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
`;
