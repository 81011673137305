import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const GlassDangerIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="146"
    height="98"
    viewBox="0 0 146 98"
    fill="none"
    {...props}
  >
    <path
      d="M93.4058 55.7602L51.3683 13.6498C46.9432 9.13371 39.591 9.00749 35.0209 13.4225C33.4279 14.8975 32.3358 16.821 31.8217 18.8495L16.3778 76.7771C15.5574 83.0299 20.0601 88.7829 26.4015 89.5573C27.6614 89.6809 28.9593 89.6307 30.2028 89.3772L87.9853 73.8944C90.9035 72.9448 93.281 70.9242 94.708 68.1983C96.133 65.4645 96.3951 62.338 95.4511 59.4465C95.0357 58.2121 94.4356 57.0774 93.4058 55.7602Z"
      fill="url(#paint0_linear_2855_757)"
    />
    <g filter="url(#filter0_f_2855_757)">
      <path
        d="M77.0483 59.4046L52.9471 35.2616C50.4101 32.6725 46.1949 32.6001 43.5747 35.1313C42.6614 35.977 42.0353 37.0798 41.7406 38.2428L32.8862 71.4542C32.4158 75.039 34.9973 78.3374 38.633 78.7814C39.3553 78.8522 40.0995 78.8235 40.8124 78.6781L73.9406 69.8015C75.6137 69.257 76.9768 68.0986 77.7949 66.5357C78.6119 64.9684 78.7621 63.1758 78.2209 61.5181C77.9828 60.8103 77.6387 60.1598 77.0483 59.4046Z"
        fill="#E31352"
      />
    </g>
    <g filter="url(#filter1_b_2855_757)">
      <path
        d="M143.067 76.1269L104.586 9.34199C100.563 2.20764 91.4061 -0.415279 84.2074 3.57681C81.7196 4.88827 79.7082 6.92891 78.3849 9.29478L39.6392 76.599C36.5162 84.1478 40.2214 92.8559 47.8965 95.9509C49.4315 96.528 51.0723 96.9004 52.7132 97.0001H130.205C134.175 96.7902 137.827 95.0591 140.526 92.1267C143.226 89.1838 144.602 85.3596 144.39 81.4252C144.285 79.7412 143.914 78.1203 143.067 76.1269Z"
        fill="#FF749F"
        fillOpacity="0.35"
      />
      <path
        d="M143.527 75.9313C143.519 75.9128 143.51 75.8947 143.5 75.8772L105.022 9.09641C105.021 9.09571 105.021 9.09501 105.02 9.0943C100.863 1.72471 91.408 -0.984827 83.9695 3.13699C81.3948 4.4955 79.3172 6.60504 77.9501 9.04788C77.9496 9.04882 77.9491 9.04976 77.9486 9.0507L39.2058 76.3495C39.195 76.3683 39.1854 76.3878 39.1771 76.4078C35.946 84.2181 39.7814 93.2176 47.7095 96.4146L47.7094 96.4148L47.7205 96.4189C49.298 97.0119 50.9879 97.3962 52.6829 97.4992C52.693 97.4998 52.7031 97.5001 52.7132 97.5001H130.205C130.214 97.5001 130.222 97.4998 130.231 97.4994C134.333 97.2826 138.107 95.4929 140.894 92.4653L140.895 92.4647C143.684 89.4237 145.109 85.4684 144.89 81.3983L144.889 81.3938C144.78 79.6482 144.394 77.9719 143.527 75.9313Z"
        stroke="url(#paint1_linear_2855_757)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#filter2_bd_2855_757)">
      <mask id="path-5-inside-1_2855_757" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.4607 57.9776C94.0014 57.9776 96.0657 55.8741 96.0657 53.356V38.5155C96.0657 35.9923 94.0014 33.9517 91.4607 33.9517C88.92 33.9517 86.8027 35.9923 86.8027 38.5155V53.356C86.8027 55.8741 88.92 57.9776 91.4607 57.9776ZM86.8027 71.2997C86.8027 73.8124 88.92 75.916 91.4607 75.916C94.0014 75.916 96.0657 73.8124 96.0657 71.242C96.0657 68.7292 94.0014 66.6781 91.4607 66.6781C88.92 66.6781 86.8027 68.7764 86.8027 71.2997Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.4607 57.9776C94.0014 57.9776 96.0657 55.8741 96.0657 53.356V38.5155C96.0657 35.9923 94.0014 33.9517 91.4607 33.9517C88.92 33.9517 86.8027 35.9923 86.8027 38.5155V53.356C86.8027 55.8741 88.92 57.9776 91.4607 57.9776ZM86.8027 71.2997C86.8027 73.8124 88.92 75.916 91.4607 75.916C94.0014 75.916 96.0657 73.8124 96.0657 71.242C96.0657 68.7292 94.0014 66.6781 91.4607 66.6781C88.92 66.6781 86.8027 68.7764 86.8027 71.2997Z"
        fill="url(#paint2_linear_2855_757)"
      />
      <path
        d="M95.6657 53.356C95.6657 55.6563 93.7774 57.5776 91.4607 57.5776V58.3776C94.2255 58.3776 96.4657 56.0918 96.4657 53.356H95.6657ZM95.6657 38.5155V53.356H96.4657V38.5155H95.6657ZM91.4607 34.3517C93.7842 34.3517 95.6657 36.2169 95.6657 38.5155H96.4657C96.4657 35.7677 94.2186 33.5517 91.4607 33.5517V34.3517ZM87.2027 38.5155C87.2027 36.2228 89.1312 34.3517 91.4607 34.3517V33.5517C88.7088 33.5517 86.4027 35.7618 86.4027 38.5155H87.2027ZM87.2027 53.356V38.5155H86.4027V53.356H87.2027ZM91.4607 57.5776C89.1381 57.5776 87.2027 55.6503 87.2027 53.356H86.4027C86.4027 56.0978 88.7019 58.3776 91.4607 58.3776V57.5776ZM91.4607 75.516C89.1378 75.516 87.2027 73.5884 87.2027 71.2997H86.4027C86.4027 74.0364 88.7022 76.316 91.4607 76.316V75.516ZM95.6657 71.242C95.6657 73.5976 93.7744 75.516 91.4607 75.516V76.316C94.2284 76.316 96.4657 74.0273 96.4657 71.242H95.6657ZM91.4607 67.0781C93.7836 67.0781 95.6657 68.9532 95.6657 71.242H96.4657C96.4657 68.5052 94.2192 66.2781 91.4607 66.2781V67.0781ZM87.2027 71.2997C87.2027 69.0004 89.1378 67.0781 91.4607 67.0781V66.2781C88.7022 66.2781 86.4027 68.5524 86.4027 71.2997H87.2027Z"
        fill="url(#paint3_linear_2855_757)"
        mask="url(#path-5-inside-1_2855_757)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2855_757"
        x="13.8308"
        y="14.2754"
        width="83.7094"
        height="83.543"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="9.5"
          result="effect1_foregroundBlur_2855_757"
        />
      </filter>
      <filter
        id="filter1_b_2855_757"
        x="13.5304"
        y="-23.293"
        width="155.881"
        height="145.293"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2855_757"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2855_757"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_bd_2855_757"
        x="71.8027"
        y="18.9517"
        width="39.2629"
        height="71.9644"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2855_757"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.376471 0 0 0 0 0.580392 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_2855_757"
          result="effect2_dropShadow_2855_757"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2855_757"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2855_757"
        x1="56.6708"
        y1="49.3061"
        x2="-0.113431"
        y2="102.967"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF759F" />
        <stop offset="1" stopColor="#FF196E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2855_757"
        x1="55.3855"
        y1="12.8092"
        x2="114.528"
        y2="94.1974"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2855_757"
        x1="91.4353"
        y1="36.3188"
        x2="82.9303"
        y2="35.5311"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.75" />
        <stop offset="1" stopColor="white" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2855_757"
        x1="88.2773"
        y1="38.8406"
        x2="102.209"
        y2="42.6492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.25" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
