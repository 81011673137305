// Lib
import styled, { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

//Theme

export const GlobalStyles = createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: ${theme.fontSize.b1};
  line-height: ${theme.lineHeight.b1};
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.color.label.primary};
  background: white;

  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
}


`;

export const AppContainer = styled.div`
  min-height: 100vh;
  position: relative;
`;

export const Wrapper = styled.div<{ $isSession: boolean }>`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: inherit;
  /* background: ${({ $isSession }) =>
    $isSession ? "rgba(42, 42, 42, 1)" : "rgba(12, 67, 90, 1)"}; */
  overflow: hidden;
  z-index: -2;

  ${({ $isSession }) =>
    $isSession
      ? "background: rgba(42, 42, 42, 1)"
      : `
    background-image: url('/BG.png'); 
    background-size: cover;
    background-position: center;
    `};
`;

export const Circle1 = styled.div`
  position: absolute;
  top: -5%;
  left: 45%;
  width: 40vw;
  height: 40vw;
  border-radius: 50%;
  background: rgba(130, 108, 221, 0.8);
  filter: blur(200px);
  transform: translate(-50%, -50%);
  z-index: -1;
`;

export const Circle2 = styled(Circle1)`
  position: absolute;
  top: 45%;
  left: 30%;
  width: 30vw;
  height: 30vw;
  background: rgba(219, 224, 228, 0.5);
`;

export const Circle3 = styled(Circle2)`
  position: absolute;
  top: 50%;
  left: 70%;
`;

export const Circle4 = styled(Circle1)`
  position: absolute;
  top: 95%;
  left: 75%;
  width: 50vw;
  height: 50vw;
  background: rgba(144, 108, 221, 0.6);
`;
