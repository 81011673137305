import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const MicIcon: FC<SvgIconConstituentValues> = ({ fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <path
      d="M22.3067 10.6401C21.8517 10.6401 21.49 11.0018 21.49 11.4568V13.3001C21.49 17.4301 18.13 20.7901 14 20.7901C9.87001 20.7901 6.51001 17.4301 6.51001 13.3001V11.4451C6.51001 10.9901 6.14835 10.6284 5.69335 10.6284C5.23835 10.6284 4.87668 10.9901 4.87668 11.4451V13.2884C4.87668 18.0368 8.52835 21.9451 13.1833 22.3651V24.8501C13.1833 25.3051 13.545 25.6668 14 25.6668C14.455 25.6668 14.8167 25.3051 14.8167 24.8501V22.3651C19.46 21.9568 23.1233 18.0368 23.1233 13.2884V11.4451C23.1117 11.0018 22.75 10.6401 22.3067 10.6401Z"
      fill={fill || "white"}
    />
    <path
      d="M14 2.3335C11.1533 2.3335 8.84332 4.6435 8.84332 7.49016V13.4635C8.84332 16.3102 11.1533 18.6202 14 18.6202C16.8467 18.6202 19.1567 16.3102 19.1567 13.4635V7.49016C19.1567 4.6435 16.8467 2.3335 14 2.3335ZM15.5283 10.4418C15.4467 10.7452 15.1783 10.9435 14.875 10.9435C14.8167 10.9435 14.7583 10.9318 14.7 10.9202C14.245 10.7918 13.7667 10.7918 13.3117 10.9202C12.9383 11.0252 12.5767 10.8035 12.4833 10.4418C12.3783 10.0802 12.6 9.70683 12.9617 9.6135C13.65 9.42683 14.3733 9.42683 15.0617 9.6135C15.4117 9.70683 15.6217 10.0802 15.5283 10.4418ZM16.1467 8.1785C16.0417 8.4585 15.785 8.62183 15.505 8.62183C15.4233 8.62183 15.3533 8.61016 15.2717 8.58683C14.455 8.2835 13.545 8.2835 12.7283 8.58683C12.3783 8.71516 11.9817 8.5285 11.8533 8.1785C11.725 7.8285 11.9117 7.43183 12.2617 7.31516C13.3817 6.90683 14.6183 6.90683 15.7383 7.31516C16.0883 7.4435 16.275 7.8285 16.1467 8.1785Z"
      fill={fill || "white"}
    />
  </svg>
);
