import { styled } from "styled-components";
import { Typography } from "styled/Typography";

export const Title = styled(Typography.H4)`
  text-align: center;
  padding: 40px 0;
`;

export const Description = styled(Typography.Body1Regular)`
  text-align: center;
  color: rgba(235, 235, 245, 0.8);
  padding: 0 0 40px;
`;
