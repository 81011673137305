// Lib
import { FC, ReactNode } from "react";
// Types
import { ModalProps as AntDModalProps } from "antd";
// Icons

// Styled

import { Button } from "styled/Buttons";
import { StyledModal, Title } from "./styled";
import { FlexContainer } from "../../styled/Box";
import { CloseIcon, InfoIcon } from "icons";

interface ModalProps extends AntDModalProps {
  isLoading?: boolean;
  closeIcon?: boolean;
  width?: number;
  title?: string;
  suffix?: ReactNode;
  onInfo?: () => void;
  onClose?: () => void;
}

export const Modal: FC<ModalProps> = ({
  isLoading = false,
  closeIcon = true,
  title,
  width = 505,
  onClose,
  children,
  suffix,
  onInfo,
  ...props
}) => {
  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <StyledModal
      centered
      maskClosable
      footer={null}
      width={width}
      {...props}
      closeIcon={<CloseIcon />}
      onCancel={handleClose}
    >
      <FlexContainer $fullwidth $justify="space-between">
        {onInfo ? (
          <Button.IconTransparent $withoutHover onClick={onInfo}>
            <InfoIcon />
          </Button.IconTransparent>
        ) : (
          <div />
        )}

        <Title>{title}</Title>

        {!!closeIcon && (
          <Button.IconTransparent
            $withoutHover
            disabled={isLoading}
            onClick={handleClose}
          >
            <CloseIcon />
          </Button.IconTransparent>
        )}

        {!!suffix && suffix}
      </FlexContainer>

      {children}
    </StyledModal>
  );
};
