import React from "react";

import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import NetworkQualityLevel from "../NetworkQualityLevel/NetworkQualityLevel";

import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useIsRecording from "twillio/hooks/useIsRecording/useIsRecording";
import useParticipantIsReconnecting from "twillio/hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import useIsTrackSwitchedOff from "twillio/hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import { Title } from "./styled";

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({
  participant,
  children,
}: MainParticipantInfoProps) {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const isLocal = localParticipant === participant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(
    p => !p.trackName.includes("screen") && p.kind === "video",
  );
  const screenSharePublication = publications.find(p =>
    p.trackName.includes("screen"),
  );

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find(p => p.kind === "audio");
  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack,
  );
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const isRecording = useIsRecording();

  return (
    <div data-cy-main-participant data-cy-participant={participant.identity}>
      <div>
        <div style={{ display: "flex" }}>
          <div>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Title $isLocal={isLocal}>
              {participant.identity}
              {isLocal && " (You)"}
              {screenSharePublication && " - Screen"}
            </Title>
          </div>
          <NetworkQualityLevel participant={participant} />
        </div>
        {isRecording && (
          <div>
            <div></div>
            <div data-cy-recording-indicator>Recording</div>
          </div>
        )}
      </div>
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <div>
          <div>avatar</div>
        </div>
      )}
      {isParticipantReconnecting && (
        <div>
          <div style={{ color: "white" }}>Reconnecting...</div>
        </div>
      )}
      {children}
    </div>
  );
}
