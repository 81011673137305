// Lib
import { FC, useEffect, useState } from "react";
// Api
// Hooks
import { useNotification } from "hooks/useNotification";
import useRoomState from "twillio/hooks/useRoomState/useRoomState";
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled

export const ReconnectingNotification: FC = () => {
  const roomState = useRoomState();
  const { openNotification } = useNotification();

  const [isDisconnected, setIsDisconnected] = useState(false);

  useEffect(() => {
    if (roomState === "reconnecting") {
      setIsDisconnected(true);
      openNotification({
        message: "The Internet connection has been interrupted.",
        description: "Please wait for reconnection.",
        type: "warning",
        duration: 40,
        updateKey: "connecting",
      });
    }
    if (roomState === "connected" && isDisconnected) {
      openNotification({
        message: "Successfuly connected",
        updateKey: "connecting",
        duration: 3,
      });
      setIsDisconnected(false);
    }
  }, [roomState]);

  return null;
};
