import styled from "styled-components";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

export const Description = styled(Typography.Body1Regular)`
  color: #fff;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;

export const Notice = styled(Typography.Body2Regular)`
  color: rgba(235, 235, 245, 0.6);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const NoticeSmall = styled(Notice)`
  color: #464649;
  font-size: 12px;
  line-height: 18px;
`;

export const InfoTitle = styled(Typography.H5)`
  color: #000;
  text-align: center;
  padding: 48px 0 24px;
`;

export const InfoDescription = styled(Typography.Body2Regular)`
  color: #000;
  text-align: center;
  opacity: 0.6;
`;

export const InfoNotice = styled.p`
  color: #464649;
  text-align: center;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  opacity: 0.4;
  padding: 24px 0;
`;

export const AccentText = styled.span`
  font-weight: 600;
`;

export const ErrorMessage = styled.p`
  color: ${theme.color.colors.pink};
  text-align: center;
  font-family: "Poppins";
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  min-height: 24px;
`;

export const ButtonTransparent = styled(Button.Transparent)`
  margin-bottom: 8px;
`;

export const Bordered = styled(Button.TransparentBordered)`
  margin-bottom: 8px;
`;
