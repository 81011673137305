// Lib
import { FC, ReactNode } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
import { Header } from "./Header";
// Styled
import { ChildrenContainer, Wrapper } from "./styled";

type LayoutProps = {
  withHeader?: boolean;
  children: ReactNode;
};

export const Layout: FC<LayoutProps> = ({ withHeader, children }) => {
  return (
    <Wrapper>
      <ChildrenContainer>
        {withHeader && <Header />}
        {children}
      </ChildrenContainer>
    </Wrapper>
  );
};
