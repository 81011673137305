// Lib
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api
// Hooks
// Actions
// Selectors
// Types
import { TwilioError } from "twilio-video";
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
import { VideoProvider } from "twillio/VideoProvider";
import { ParticipantProvider } from "twillio/ParticipantProvider";
import AppStateProvider from "twillio/StateProvider";
import { Call } from "./components";
// Styled

const CONNECTION_ERRORS = [53405, 53000, 53001];

export const Session: FC = () => {
  const navigate = useNavigate();

  const { id, name, token } = useParams();

  const handleError = (error: TwilioError | Error) => {
    console.log(error.code);
    console.log(error);
    if (CONNECTION_ERRORS.includes(error.code)) {
      navigate(`/disconnected/${id}/${name}/${token}`);

      return;
    }

    if (error.code === 20101) {
      window.sessionStorage.removeItem("status");

      navigate("/expired");
      return;
    }

    const isStarted = window.sessionStorage.getItem("status") === "started";

    if (isStarted) {
      window.sessionStorage.removeItem("status");

      navigate(`/congrats/${id}`);
      return;
    }

    navigate("/expired");
  };
  return (
    <AppStateProvider>
      <VideoProvider onError={error => handleError(error)}>
        <ParticipantProvider>
          <Call />
        </ParticipantProvider>
      </VideoProvider>
    </AppStateProvider>
  );
};
