// Lib
import { FC } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
// Theme
// Constants
// Helpers
// Utils
// Icons
// Layouts
// Components
// Styled
import { Answer, Container, QuestionTitle } from "./styled";

interface QuestionProps {
  question: string;
  answer: string;
}

export const Question: FC<QuestionProps> = ({ question, answer }) => {
  return (
    <Container>
      <QuestionTitle $padding="0 0 16px">{question}</QuestionTitle>

      <Answer>{answer}</Answer>
    </Container>
  );
};
