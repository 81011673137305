import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const EndCallIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <path
      d="M12.8917 17.4418L10.7333 19.6002C10.2783 20.0552 9.555 20.0552 9.08833 19.6118C8.96 19.4835 8.83166 19.3668 8.70333 19.2385C7.50166 18.0252 6.41666 16.7535 5.44833 15.4235C4.49166 14.0935 3.72166 12.7635 3.16166 11.4452C2.61333 10.1152 2.33333 8.8435 2.33333 7.63016C2.33333 6.83683 2.47333 6.0785 2.75333 5.3785C3.03333 4.66683 3.47666 4.0135 4.095 3.43016C4.84166 2.69516 5.65833 2.3335 6.52166 2.3335C6.84833 2.3335 7.175 2.4035 7.46666 2.5435C7.77 2.6835 8.03833 2.8935 8.24833 3.19683L10.955 7.01183C11.165 7.3035 11.3167 7.57183 11.4217 7.8285C11.5267 8.0735 11.585 8.3185 11.585 8.54016C11.585 8.82016 11.5033 9.10016 11.34 9.3685C11.1883 9.63683 10.9667 9.91683 10.6867 10.1968L9.79999 11.1185C9.67166 11.2468 9.61333 11.3985 9.61333 11.5852C9.61333 11.6785 9.625 11.7602 9.64833 11.8535C9.68333 11.9468 9.71833 12.0168 9.74166 12.0868C9.95166 12.4718 10.3133 12.9735 10.8267 13.5802C11.3517 14.1868 11.9117 14.8052 12.5183 15.4235C12.635 15.5402 12.7633 15.6568 12.88 15.7735C13.3467 16.2285 13.3583 16.9752 12.8917 17.4418Z"
      fill="white"
    />
    <path
      d="M25.6317 21.3849C25.6317 21.7115 25.5733 22.0499 25.4567 22.3766C25.4217 22.4699 25.3867 22.5632 25.34 22.6565C25.1417 23.0766 24.885 23.4732 24.5467 23.8466C23.975 24.4766 23.345 24.9316 22.6333 25.2232C22.6217 25.2232 22.61 25.2349 22.5983 25.2349C21.91 25.5149 21.1633 25.6665 20.3583 25.6665C19.1683 25.6665 17.8967 25.3866 16.555 24.8149C15.2133 24.2432 13.8717 23.4732 12.5417 22.5049C12.0867 22.1666 11.6317 21.8282 11.2 21.4665L15.015 17.6515C15.3417 17.8965 15.6333 18.0832 15.8783 18.2115C15.9367 18.2349 16.0067 18.2699 16.0883 18.3049C16.1817 18.3399 16.275 18.3515 16.38 18.3515C16.5783 18.3515 16.73 18.2816 16.8583 18.1532L17.745 17.2782C18.0367 16.9866 18.3167 16.7649 18.585 16.6249C18.8533 16.4615 19.1217 16.3799 19.4133 16.3799C19.635 16.3799 19.8683 16.4265 20.125 16.5315C20.3817 16.6365 20.65 16.7882 20.9417 16.9866L24.8033 19.7282C25.1067 19.9382 25.3167 20.1832 25.445 20.4749C25.5617 20.7665 25.6317 21.0582 25.6317 21.3849Z"
      fill="white"
    />
  </svg>
);
