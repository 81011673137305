// Lib
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Reset } from "styled-reset";
// Api
// Hooks
import { useAppSelector } from "hooks";
// Actions
// Selectors
import { isAuth } from "rtk/selectors";
// Types
// Theme
// Constants
import { DELETE_ACCOUNT, LOGIN, ROUTES } from "consts";
// Helpers
import { token } from "utils/handleToken";
// Utils
// Icons
// Layouts
// Components
import { DeleteAccount, Login } from "pages";
import { AuthRequired, Layout } from "components";
// Styled
import { AppContainer, GlobalStyles, Wrapper } from "styles";

const App = () => {
  const isAuthorized = useAppSelector(isAuth);

  const userAuthorized = isAuthorized && !!token.access.get();

  const redirectPath = userAuthorized ? "/home" : LOGIN;

  const { pathname } = useLocation();

  const isSession = pathname.includes("session");

  return (
    <>
      <Reset />
      <GlobalStyles />

      <AppContainer>
        <Wrapper $isSession={isSession}></Wrapper>

        <Routes>
          <Route path="*" element={<Navigate replace to={redirectPath} />} />

          <Route
            path={LOGIN}
            element={
              <Layout>
                <Login />
              </Layout>
            }
          />

          <Route
            path={DELETE_ACCOUNT}
            element={
              <Layout>
                <DeleteAccount />
              </Layout>
            }
          />

          {Object.keys(ROUTES).map(key => {
            const { Component, path } = ROUTES[key];
            return (
              <Route
                key={key}
                path={path}
                element={
                  <AuthRequired>
                    <Layout withHeader>
                      <Component />
                    </Layout>
                  </AuthRequired>
                }
              />
            );
          })}
        </Routes>
      </AppContainer>
    </>
  );
};

export default App;
