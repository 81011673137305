import { Modal } from "antd";
import styled from "styled-components";
import { Typography } from "styled/Typography";

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
    border-radius: 24px;
  }
  .ant-modal-close {
    display: none;
  }
`;

export const Title = styled(Typography.H5)`
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
