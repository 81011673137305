import { FC } from "react";
import { SvgIconConstituentValues } from "types/common";

export const LogoIcon: FC<SvgIconConstituentValues> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="158"
    height="38"
    viewBox="0 0 158 38"
    fill="none"
    {...props}
  >
    <path
      d="M5.43153 35.3895C3.71888 34.296 2.3957 32.7684 1.46142 30.8064C0.52769 28.8449 0.0605469 26.5617 0.0605469 23.9578C0.0605469 21.3887 0.52769 19.1232 1.46142 17.1612C2.3957 15.1997 3.71888 13.6805 5.43153 12.6035C7.14419 11.5277 9.14219 10.9893 11.4255 10.9893C13.7089 10.9893 15.7069 11.5277 17.4195 12.6035C19.1322 13.6805 20.4554 15.1997 21.3896 17.1612C22.3239 19.1232 22.7911 21.3887 22.7911 23.9578C22.7911 26.5269 22.3239 28.8013 21.3896 30.7804C20.4554 32.7596 19.1322 34.296 17.4195 35.3895C15.7069 36.4835 13.7089 37.0303 11.4255 37.0303C9.14219 37.0303 7.14419 36.4835 5.43153 35.3895ZM15.811 30.0772C16.8659 28.6017 17.3936 26.5617 17.3936 23.9578C17.3936 21.4236 16.8747 19.4184 15.8369 17.9423C14.799 16.4668 13.3282 15.7287 11.4255 15.7287C9.55748 15.7287 8.10432 16.4756 7.06649 17.9683C6.02866 19.4616 5.50919 21.4579 5.50919 23.9578C5.50919 26.5274 6.02866 28.5586 7.06649 30.0512C8.10432 31.5445 9.55752 32.2908 11.4255 32.2908C13.2935 32.2908 14.755 31.5533 15.811 30.0772Z"
      fill="white"
    />
    <path
      d="M46.0139 13.5932C47.5531 15.3302 48.3237 17.6737 48.3237 20.6243V36.5616H42.9781V21.0411C42.9781 19.3405 42.571 18.0292 41.7585 17.1087C40.9454 16.1893 39.7775 15.7288 38.2555 15.7288C36.7335 15.7288 35.5133 16.3275 34.5966 17.5255C33.68 18.7235 33.2216 20.3118 33.2216 22.291V36.5616H27.8766V18.3327C27.8766 17.4653 27.7119 16.8406 27.3836 16.458C27.0542 16.076 26.5093 15.8847 25.7486 15.8847C25.4021 15.8847 25.1261 15.9029 24.9185 15.9372V11.4581C25.7139 11.3199 26.5094 11.2496 27.3054 11.2496C30.1082 11.2496 31.7685 12.4305 32.2874 14.7912C33.1181 13.576 34.1901 12.6389 35.5051 11.9788C36.8195 11.3199 38.2556 10.9893 39.8123 10.9893C42.4074 10.9893 44.4743 11.8573 46.0139 13.5932Z"
      fill="white"
    />
    <path
      d="M77.2282 19.9994L79.8228 11.458H85.5832V11.6139L77.4359 36.8219H75.3079L68.5096 21.3014L61.7114 36.8219H59.5834L51.436 11.6139V11.458H57.1441L59.7393 19.9994L61.3996 26.5617L67.4718 12.2911H69.4957L75.6191 26.5097L77.2282 19.9994Z"
      fill="white"
    />
    <path
      d="M107.277 29.5305C107.277 30.676 107.493 31.4145 107.926 31.7441C108.358 32.0741 109.128 32.1869 110.235 32.0824V36.5615C109.509 36.7351 108.696 36.8219 107.796 36.8219C106.481 36.8219 105.4 36.5355 104.553 35.9628C103.704 35.3895 103.159 34.5481 102.918 33.4369C101.603 35.8324 99.1988 37.0303 95.7046 37.0303C93.1783 37.0303 91.1803 36.3271 89.7106 34.9207C88.2392 33.5148 87.5049 31.5793 87.5049 29.1136C87.5049 26.6839 88.3174 24.7567 89.9441 23.3326C91.5698 21.9096 93.7325 21.1975 96.4312 21.1975C98.2992 21.1975 100.132 21.5447 101.932 22.239V20.8326C101.932 19.0275 101.569 17.7339 100.842 16.9527C100.115 16.1716 98.8523 15.7807 97.0537 15.7807C94.8392 15.7807 92.5388 16.3363 90.1513 17.4475L88.9581 13.0724C91.6563 11.6842 94.4591 10.9893 97.3649 10.9893C100.583 10.9893 103.039 11.8224 104.734 13.4892C106.429 15.1561 107.277 17.6035 107.277 20.8326V29.5305ZM100.557 30.9629C101.473 29.939 101.932 28.5757 101.932 26.8741V25.8845C100.479 25.4339 98.9387 25.2078 97.3131 25.2078C95.9288 25.2078 94.8563 25.529 94.0955 26.1714C93.3342 26.8138 92.9541 27.7426 92.9541 28.9577C92.9541 30.1032 93.2824 30.9806 93.9401 31.5876C94.5968 32.1957 95.531 32.4993 96.7424 32.4993C98.368 32.4993 99.6395 31.9873 100.557 30.9629Z"
      fill="white"
    />
    <path
      d="M131.149 12.6561L129.125 17.1347C128.503 16.8224 127.914 16.588 127.361 16.4321C126.807 16.2756 126.167 16.1976 125.441 16.1976C123.814 16.1976 122.509 16.7361 121.523 17.8119C120.536 18.8889 120.043 20.4341 120.043 22.4475V36.5616H114.698V18.3327C114.698 17.4305 114.525 16.7964 114.179 16.432C113.833 16.0671 113.314 15.8847 112.622 15.8847C112.241 15.8847 111.948 15.9029 111.74 15.9372V11.4581C112.467 11.3199 113.245 11.2496 114.075 11.2496C116.878 11.2496 118.538 12.4134 119.057 14.7392C119.749 13.5241 120.709 12.5958 121.938 11.9529C123.165 11.3105 124.455 10.9893 125.804 10.9893C127.88 10.9893 129.661 11.5449 131.149 12.6561Z"
      fill="white"
    />
    <path
      d="M155.023 29.6881C155.023 30.5909 155.17 31.2245 155.465 31.5888C155.758 31.9537 156.269 32.1356 156.996 32.1356C157.376 32.1356 157.687 32.1184 157.93 32.0836V36.5627C157.203 36.7009 156.442 36.7712 155.646 36.7712C154.159 36.7712 153.017 36.4246 152.221 35.7296C151.425 35.0353 150.941 33.9589 150.768 32.5005C149.938 33.9937 148.856 35.1226 147.524 35.8855C146.192 36.649 144.765 37.0316 143.243 37.0316C141.097 37.0316 139.264 36.4505 137.742 35.2868C136.22 34.1236 135.061 32.5524 134.265 30.5732C133.469 28.5941 133.071 26.4065 133.071 24.011C133.071 21.5807 133.495 19.3754 134.343 17.3962C135.19 15.4176 136.436 13.8547 138.079 12.7092C139.722 11.5632 141.686 10.9904 143.97 10.9904C146.149 10.9904 148.052 11.4769 149.678 12.4489V0.10498H155.023L155.023 29.6881ZM148.199 30.0525C149.185 28.5598 149.678 26.5458 149.678 24.011V17.1359C148.225 16.1988 146.547 15.73 144.645 15.73C142.741 15.73 141.245 16.4509 140.155 17.891C139.065 19.3323 138.521 21.3729 138.521 24.011C138.521 26.5806 139.005 28.6029 139.974 30.0784C140.942 31.5545 142.309 32.292 144.073 32.292C145.838 32.292 147.213 31.5457 148.199 30.0525Z"
      fill="white"
    />
  </svg>
);
